import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from 'src/app/Models/User';
import { MustMatch } from 'src/app/Services/must-match.validator';
import { ScoreGeniusService } from 'src/app/Services/score-genius.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  cForm: UntypedFormGroup;
  submitted = false;
  type = 'password';
  typeConfirmPassword = 'password';
  eyessrc = 'https://assets.dev.ooki.io/assetsImage/eyes.png';
  eyessrcForConfirmPassword = 'https://assets.dev.ooki.io/assetsImage/eyes.png';
  show = false;
  showForConfirmPassword = false;
  success = false;
  IsImageLoaded: boolean = false;
  userModel = new User('', '');
  Islength = false;
  IsUpperLowerCase = false;
  IsNumber = false;
  IsSymbol = false;
  passwordMatch = false;
  userEmail: string;
  RequirmentPassword: boolean = false;
  img_src = this.imgSrc + 'eye.svg';
  P_pattern = '';
  loader: boolean = true;
  message:string='We are checking your request';
  constructor( private fb: UntypedFormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private _services: ScoreGeniusService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['refid']) {
        this.userEmail = params['refid'];
      }
    });
  }

  ngOnInit() {
     this.cForm = this.fb.group({
      NewPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32)]],
      ConfirmNewPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32)]],
     }, {
        validator: MustMatch('NewPassword', 'ConfirmNewPassword')
    });
    $('#txtConfirmPass').on('cut copy paste', function (e) {
      e.preventDefault();
    });
    this.CheckUser(this.userEmail)
  }

  CheckUser(email:string){
    this._services.CheckUser(email).subscribe((res: any) => {
      if (res != null && res.statusCode == 0) {
        if (res.message =='NewUser') {
          this.loader = false;
        } else if (res.message == 'UserExist' ) {
          this.message = 'Your account already exist. Redirecting to login page.';
          setTimeout(()=>{                         
            this.router.navigate(['/login']);
        }, 2000);
        }
      }
    });
  }
  get forgotPassword() {
    return this.cForm.controls;
  }
  OnPasswordPattern(PatternPass): any {
    this.P_pattern = PatternPass;
    if (this.P_pattern.search(/(?=.*?[a-z])(?=.*?[A-Z])/) != -1) {
      this.IsUpperLowerCase = true;
    } else {
      this.IsUpperLowerCase = false;
    }
    if (this.P_pattern.search(/\d/) != -1) {
      this.IsNumber = true;
    } else {
      this.IsNumber = false;
    }
    if (this.P_pattern.search(/[!@#$%^&*(),.?":{}|<>]/) != -1) {
      this.IsSymbol = true;
    } else {
      this.IsSymbol = false;
    }
    if (this.P_pattern.toString().length >= 8) {
      this.Islength = true;
    } else {
      this.Islength = false;
    }
  }

  onSubmit() {
    this.submitted = true;
   this.IsImageLoaded= true;
    if (this.cForm.invalid) {
      this.IsImageLoaded= false;
      return;
    }

    if (!this.IsUpperLowerCase || !this.IsNumber || !this.IsSymbol || !this.Islength) {
      this.RequirmentPassword = true;
      return;
    }
    this.userModel.password = this.cForm.get('NewPassword').value;
    this.userModel.username = this.userEmail;
    this._services.SaveUserInOoki(this.userModel).subscribe((res: any) => {
      if (res != null && res.statusCode ==0) {
        this.router.navigate(['/login']);
      } else {
        console.log('Error found');
      }
    });
  }

  PasswordValidator(confirmPasswordInput: string) {
    let confirmPasswordControl: UntypedFormControl;
    let passwordControl: UntypedFormControl;
    return (control: UntypedFormControl) => {
      if (!control.parent) {
        return null;
      }
      if (!confirmPasswordControl) {
        confirmPasswordControl = control;
        passwordControl = control.parent.get(confirmPasswordInput) as UntypedFormControl;
        passwordControl.valueChanges.subscribe(() => {
          confirmPasswordControl.updateValueAndValidity();
        });
      }
      if (passwordControl.value !== confirmPasswordControl.value && confirmPasswordControl.value) {
        this.passwordMatch = true;
        return {
          notMatch: true
        };
      } else {
        this.passwordMatch = false;
      }
      return null;
    };
  }
  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.eyessrc = 'https://assets.dev.ooki.io/assetsImage/eye.png';
    } else {
      this.type = 'password';
      this.eyessrc = 'https://assets.dev.ooki.io/assetsImage/eyes.png';
    }
  }
  toggleShowForConfirmPassword() {
    this.showForConfirmPassword = !this.showForConfirmPassword;
    if (this.showForConfirmPassword) {
      this.typeConfirmPassword = 'text';
      this.eyessrcForConfirmPassword = 'https://assets.dev.ooki.io/assetsImage/eye.png';
    } else {
      this.typeConfirmPassword = 'password';
      this.eyessrcForConfirmPassword = 'https://assets.dev.ooki.io/assetsImage/eyes.png';
    }
  }

  ShowHidePass() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.img_src = this.imgSrc + 'eye-green.svg';
    } else {
      this.type = 'password';
      this.img_src = this.imgSrc + 'eye.svg';
    }
  }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import * as config from '../assets/app-config.json';
export const environment = {
  production: false,
  name: 'Local',
  imgBasURL: '../../../assets/Blossm-assets/app-assets/images/',
  configUrl: config.default,
  urlOoki: 'https://app.dev.blossm.me',
  apiUrl: 'https://api.dev.creditgenius.co.uk',
  TruelayerAuthorizationLink: 'https://auth.truelayer.com/?response_type=code&client_id=scoregenius-khwd&nonce=4206444574&scope=info%20accounts%20balance%20transactions%20cards%20direct_debits%20standing_orders%20offline_access&redirect_uri=http://localhost:4200/callback&providers=uk-ob-all%20uk-oauth-all%20uk-cs-mock',
  imgEnergyBasURL: 'https://savingexpert.s3-eu-west-1.amazonaws.com/assetsImage/',
  energyApiUrl: 'https://api2.dev.ooki.io/energy/',
  userName: '',
  password: '',
  imgAccounturl: 'https://assets.dev.blossm.me/',
  TooltipDerogatory: 'Derogatory marks are negative items such as missed payments, bankruptcy, county court judgements and debt relief orders.',
  TooltipLoansGuaranteedRate: 'This means the lender cannot change the rate advertised as you progress the application.',
  TooltipLoansVariableRate: 'This means a lender may change the rate to a higher APR as you progress through the application.',
  TooltipAccountOverviewDebts: 'Your debt balance includes your short term lending and credit card balances from your Experian Credit Report.',
  TooltipAccountOverviewConnectedAccounts: 'You can filter transactions by selecting or unselecting the connected accounts below.',
  TooltipForPreApproved100: 'Your chances of receiving this loan are extremely high, however this is subject to further lender checks.',
  ArticleId: '2lgMiy79NReArMaeFgLIs1'
};

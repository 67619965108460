import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})
export class ErrorpageComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  errorMessage: any;
  constructor(private route: Router,private cookieService: CookieService) {
    sessionStorage.clear();
    localStorage.clear();
    cookieService.delete('username');
  }

  ngOnInit() {
    //Code Initialization Here
  }

  GoLogin() {
    this.route.navigate(['login']);
  }

}

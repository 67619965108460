import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './Extras/AuthGuard/auth.guard';
import { LoginComponent } from './Pages/login/login/login.component';
import { AppConfigService } from './Services/AppConfigService.service';
import { EnergyLinxService } from './Services/energy-linx.service';
import { MarketPlaceService } from './Services/marketplace.service';
import { ScoreGeniusService } from './Services/score-genius.service';
import { TransUnionService } from './Services/transunion.service';
import { TruelayerService } from './Services/truelayer.service';
import { StorageService } from './Services/StorageService';
import { AuthenticationInterceptor } from './Extras/Interceptors/auth.interceptor';
import { CMSIntegrationService } from './Services/cmsintegration.service';
import { ErrorpageComponent } from './Pages/errorpage/errorpage.component';
import { InactivityTimerComponent } from './Components/inactivity-timer/inactivity-timer.component';
import { ExperianService } from './Services/experian.service';
import { SentryErrorHandler } from './Services/SentryErrorHandle';
import { SavingExpertService } from './Services/saving-expert.service';
import { CacheService } from './Services/cache.service';
import { SessionService } from './Services/Session.service';
import { GlobalService } from './Services/globalService';
import { ActivateAccountPageComponent } from './Pages/sign-up/activate-account-page/activate-account-page.component';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    ErrorpageComponent,
    LoginComponent,
    ActivateAccountPageComponent,
    InactivityTimerComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  providers:
    [{ provide: 'googleTagManagerId', useValue: 'GTM-NV4F2TP' },
      ScoreGeniusService,
      CacheService,
      MarketPlaceService,
      StorageService,
      ExperianService,
      Meta,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
      TruelayerService,
      AuthGuard,
      EnergyLinxService,
      TransUnionService,
      DatePipe,
      CookieService,
      CMSIntegrationService,
      AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
      SavingExpertService,
      SessionService,
      GlobalService,
      BnNgIdleService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

<div id="page-wrapper" class="container">
  <div id="page-container" class="container-fluid">
  <div id="failed-info-txt" class="text-center failed-normal">
    Link expired
    </div>
      <div id="failed-info-txt-txt" class="text-center">
        This password reset link has now expired, please request a new one.
      </div>
      <div id="failed-info-txt-txt" class="text-center  failed-weight">
        Note, the link expires after 24 hours
      </div>
      <div class="text-center">
        <button id="btn-try-again" (click)="GoLogin()">Try Again</button>
      </div>
  </div>
</div>


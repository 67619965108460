import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdditionalInformation, AdditionalInfo, AdditionalInform } from '../Models/AdditioonalInformation';
import { ApiAuthPassword, ApiLogin } from '../Models/ApiLogin';
import { PersonalDetails } from '../Models/PersonalDetails';
import { Profilepic } from '../Models/Profilepic';
import { ResetPassword } from '../Models/resetPassword';
import { AWSConfirmPasswordRequest, User,UserEmailId, CheckDeviceRequest, PasswordResetUpdateRequest, UserAvailability, RegisterInterestModel,  } from '../Models/User';
import { Userlogin } from '../Models/Userlogin';
import { UserJourney, UserProfileDetails } from '../Models/UserProfileDetails';
import { TokenDetails } from '../Models/AWSSocialSign';
import  jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { CryptographyService } from './cryptography.service';
import { ReferredTo, ReferredTracking, SaveReferralTracking } from '../Models/Referral';

@Injectable()



export class ScoreGeniusService {
  private orderNumSource = new BehaviorSubject<number>(0);
  currentOrder = this.orderNumSource.asObservable();
  private _newUserSignup: BehaviorSubject<Userlogin> = new BehaviorSubject(null);
  awscallbackerrormessage;
  returnUrl;
  // Get globelUrl from app config
  url = environment.configUrl;
  readonly rootUrl = environment.apiUrl;
  readonly awsLambdaAPIUrl = this.url['awsLambdaApiURL'];
  readonly AwsApiProfilePic = this.url['AwsApiProfilePic'];
  readonly AwsApiProfileData = this.url['AwsApiProfileData'];
  readonly AwsApiProfilePicDelete = this.url['AwsApiProfilePicDelete'];
  readonly MarketPlaceAPIURL = this.url['MarketPlaceAPIURL'];
  readonly baseUrl = this.url['BaseUrl'];
  readonly UserDetailsAPIURL = this.url['UserDetailsAPIURL'];
  readonly baseUrlSendMail = this.url['baseUrlSendMail'];

  private _personalInfo: BehaviorSubject<PersonalDetails> = new BehaviorSubject(null);
  get personalInformation() {
    return this._personalInfo;
  }
  personalInfo(details: PersonalDetails) {
    this._personalInfo.next(details);
  }

  // User Signup
  get newUserSignup() {

    // do some logic here if needed
    return this._newUserSignup;
  }
  newUserSignupDetail(UserSignup: Userlogin) {
    this._newUserSignup.next(UserSignup);
  }

  setAWSCallBackMessage(val: string) {
    this.awscallbackerrormessage = val;
  }

  getAWSCallBackMessage() {
    return this.awscallbackerrormessage;
  }

  // Store Shared/Return Url
  setreturnUrl(val: string) {
    this.returnUrl = val;
  }
  getreturnUrl() {
    return this.returnUrl;
  }

 // readonly rootUrl ='https://devapi.dev.scoregenius.co.uk';

  constructor(private _http: HttpClient, private crypto_service: CryptographyService) { }



  // test_url = "https://localhost:44387"

  // encryption done
  userloginAuth(user: ApiLogin) {
    // object.assign create a copy of object without modifying the existing one
    let user_encrypted = Object.assign({}, user);

    // updating the value in the copied object by calling encrypt method.
    user_encrypted.username = this.crypto_service.encrypt(user.username);
    user_encrypted.password = this.crypto_service.encrypt(user.password);

    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });

    let result =  this._http.post(this.rootUrl + '/public-auth/Login', user_encrypted, { headers: requestheader });

    return result;
  }

  // sendVerificationEmail(){
  //   var result =  this._http.post(this.rootUrl+ '/user/SendVerificationEmail', {});
  //   return result;
  // }
  verifyEmailToken(emailToken:string){
    let result =  this._http.post(this.rootUrl+ '/public-auth/VerifyEmailToken', {"Token":emailToken} );
    return result;
  }
  validatePassword(user: ApiAuthPassword) {
    // object.assign create a copy of object without modifying the existing one
    let user_encrypted = Object.assign({}, user);

    // updating the value in the copied object by calling encrypt method.
    // user_encrypted.username = this.crypto_service.encrypt(user.username);
    user_encrypted.password = this.crypto_service.encrypt(user.password);

   let result =  this._http.post(this.rootUrl + '/user/ValidateUser', user_encrypted);

    return result;
  }

  // encryption done
  userSinUpAWS(userProfileDetails: UserProfileDetails) {
    userProfileDetails.password = this.crypto_service.encrypt(userProfileDetails.password);
    userProfileDetails.username = this.crypto_service.encrypt(userProfileDetails.username);
    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });
     return this._http.post(this.rootUrl + '/public-auth/SignUp', userProfileDetails, { headers: requestheader });
  }

  userAWSSocialSignUp(token: TokenDetails) {
    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });
    return this._http.post(this.rootUrl + '/public-auth/SocialSignup', token, { headers: requestheader });
  }

  userAWSSocialSignIn(token: TokenDetails) {
    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });
    return this._http.post(this.rootUrl + '/public-auth/SocialSignin', token, { headers: requestheader });
  }

  SaveAddress(addressdata) {
     return this._http.post(this.rootUrl + '/public-auth/SaveAddress', addressdata);
  }

  SavePersonalDetails(PersonalDetailsReq) {
    return this._http.post(this.rootUrl + '/public-auth/SavePersonalDetails', PersonalDetailsReq);
  }

  TrackJourney(userJourney: UserJourney) {
    return this._http.post(this.rootUrl + '/public-auth/TrackJourney', userJourney);
  }

  // For AWS
  // encryption done
  userResetPasswordAWS(resetPassword: ResetPassword) {

    // object.assign create a copy of object without modifying the existing one
    let resetpassword_encrypted = Object.assign({}, resetPassword);


    // updating the value in the copied object by calling encrypt method.
    resetpassword_encrypted.userName = this.crypto_service.encrypt(resetPassword.userName);
    resetpassword_encrypted.oldpassword = this.crypto_service.encrypt(resetPassword.oldpassword);
    resetpassword_encrypted.newpassword = this.crypto_service.encrypt(resetPassword.newpassword);
    resetpassword_encrypted.confirmpassword = this.crypto_service.encrypt(resetPassword.confirmpassword);

    return this._http.post(this.rootUrl + '/user/ChangePassword', resetpassword_encrypted);

  }

  // encryption done
  forgotPassword(userName: User) {
    // object.assign create a copy of object without modifying the existing one
    let user_encrypted = Object.assign({}, userName);


    // updating the value in the copied object by calling encrypt method.
    user_encrypted.username = this.crypto_service.encrypt(userName.username);

    return this._http.post(this.rootUrl + '/public-auth/ForgotPassword', user_encrypted );

  }

  // encryption done
  AWSConfirmForgotPassword(AWSConfirmPasswordRequest: AWSConfirmPasswordRequest) {
    // object.assign create a copy of object without modifying the existing one
    let AWSConfirmPasswordRequest_encrypted = Object.assign({}, AWSConfirmPasswordRequest);
    AWSConfirmPasswordRequest_encrypted.UserName = this.crypto_service.encrypt(AWSConfirmPasswordRequest.UserName);
    AWSConfirmPasswordRequest_encrypted.NewPassword = this.crypto_service.encrypt(AWSConfirmPasswordRequest.NewPassword);
    AWSConfirmPasswordRequest_encrypted.ConfirmationCode = this.crypto_service.encrypt(AWSConfirmPasswordRequest.ConfirmationCode);

    return this._http.post(this.rootUrl + '/public-auth/ConfirmForgotPassword', AWSConfirmPasswordRequest_encrypted);

  }

  GetTrackingDetails(TrackingId): Observable<any> {
    return this._http.get(this.rootUrl + '/public-auth/GetTrackingDetails/' + TrackingId);
  }

  ResetPasswordUpdate(passwordResetUpdateModal: PasswordResetUpdateRequest) {
    return this._http.post(this.rootUrl + '/public-auth/ResetPasswordUpdate', passwordResetUpdateModal);
  }

  SendMail(userMailId: UserEmailId) {
    return this._http.post(this.baseUrlSendMail + '/SendMail', { userMailId });
  }

  getAddress(Postcode): Observable<any> {
    let random = Math.random().toString().replace(".", "");
    return this._http.get<any>(this.rootUrl + '/public-auth/GetAddress/' + encodeURIComponent(Postcode)+'?key='+random);
  }

  // encryption done
  UserAvailability(emailAddress: UserAvailability) {
    let email ='';
    if(emailAddress.email){
      email = emailAddress.email
    }else{
      email = String(emailAddress);
    }
    let email_encrypted = Object.assign({}, emailAddress);
    email_encrypted.tid = localStorage.getItem("tid");
    email_encrypted.affid =localStorage.getItem("affid");
    email_encrypted.sub1 =localStorage.getItem("sub1");
    email_encrypted.offerid = localStorage.getItem("offerid");
    email_encrypted.email = this.crypto_service.encrypt(String(email));
    return this._http.post(this.rootUrl + '/public-auth/UserAvailability', email_encrypted );
  }

  SignoutFromAws() {
    return this._http.get(this.rootUrl + '/user/Signout');
  }

  GetCountryList() {
    return this._http.get(this.rootUrl + '/public-auth/GetCountryList');
  }
  GetMaritalList() {
    return this._http.get(this.rootUrl + '/marketplace/MaritalStatus');
  }
  IndustryList() {
     return this._http.get(this.rootUrl + '/marketplace/Industry');
   }

  UpdateAdditionalInformation(additionalInfo: AdditionalInfo) {
    return this._http.post(this.rootUrl + '/marketplace/SaveEligibility', additionalInfo);

  }

  UpdateAdditionalInformationForMyPofile(additionalInform: AdditionalInform) {

    return this._http.post(this.rootUrl + '/marketplace/SaveEligibility', additionalInform);

  }
  GetMyProfileInformation(removeCache='') {
    return this._http.get(this.rootUrl + '/user/GetMyProfileInformation'+removeCache);
  }

  GetAdditionalInformation(additionalInfo: AdditionalInformation) {
    return this._http.post(this.rootUrl + '/api/UserAccount/GetAdditionalInformation', additionalInfo);
  }

  GetUserInformation() {
    return this._http.get<any>(this.rootUrl + '/user/GetUserBasicInformations');
  }

  CheckSignUpJourney() {

    return this._http.post(this.rootUrl + '/user/VerifyUserSignUpJourney', {});

  }

  GetProfileData() {


    let result = this._http.get(this.rootUrl + '/account/UserProfileData');

    return result;
  }

  SaveProfilePicToS3(profilepic: Profilepic) {
    return this._http.post(this.AwsApiProfilePic, profilepic);
  }

  DeleteProfilePicFromS3(profilepic: Profilepic) {
    return this._http.post(this.AwsApiProfilePicDelete, profilepic);
  }

  IsEligibility() {
    return this._http.get<any>(this.rootUrl + '/marketplace/IsUserEligibility');
  }

  IsTokenExpired(token:string): boolean {
    if (token != null && token != '') {
      let decoded = jwt_decode(token) as any;
      if (decoded.exp === undefined) {
        return true;
      }
      const date = new Date(0);
      // Convert Exp Time to UTC
      let tokenExpDate = date.setUTCSeconds(decoded.exp);
      // let tokenExpDate= 1580895596364;
      // if Value of Token time greater than
      if (tokenExpDate.valueOf() > new Date().valueOf()) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  AWSRefreshToken() {
    return this._http.post(this.rootUrl + '/user/AWSRefreshToken', {} );
  }

  DeviceCheck(checkdevicerequest: CheckDeviceRequest) {
    return this._http.post(this.rootUrl + '/creditscore/DeviceCheck', checkdevicerequest);
  }

  Orchestrator() {
    return this._http.post(this.rootUrl + '/creditscore/UpdatedCreditData', {} );
  }
  formatDob(dob: any) {
    if (dob != '') {
      let arrDob = dob.split('/');
      return arrDob[2] + '-' + arrDob[1] + '-' + arrDob[0];
    }
    return null;
  }

  VerifyEmail(emailid: string) {
    return this._http.get(this.rootUrl + '/public-auth/VerifyEmailAddress/' + emailid);
  }

   GetUserSessionDetails() {
     return this._http.get(this.rootUrl + '/user/GetUserSessionDetails');
   }

   GetUserDetails() {
    return this._http.get(this.rootUrl + '/user/UserDetails');
  }

  ReferTo(referredTo: ReferredTo) {
    return this._http.post(this.rootUrl + '/user/Refertofriend', referredTo);
  }

  UReferralTracking(model: ReferredTracking) {
    return this._http.post(this.rootUrl + '/user/UpdateReferral', model);
  }

  ReferralDetails() {
    return this._http.get(this.rootUrl + '/user/ReferralDetails');
  }

  SaveTracking(saveReferralTracking: SaveReferralTracking) {
    return this._http.post(this.rootUrl + '/public-auth/SaveTracking', saveReferralTracking);
  }

  ReferralRewarded() {
    return this._http.get(this.rootUrl + '/user/ReferralRewarded');
  }

  CheckUser(email: string) {
    return this._http.get(this.rootUrl + '/public-auth/UserCheck?email=' + email);
  }

  SaveUserInOoki(user: User){
    user.password = this.crypto_service.encrypt(user.password);
    return this._http.post(this.rootUrl +'/creditgenius/SaveUserInOoki', user);    
  }

  RegisterInterest(registerInterestModel: RegisterInterestModel) {
    return this._http.post(this.rootUrl + '/user/RegisterInterest', registerInterestModel);
  }

  UpdateWalkthrough() {
    return this._http.post(this.rootUrl + '/user/walkthrough', null);
  }

}

import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/Services/auth.service';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inactivity-timer',
  templateUrl: './inactivity-timer.component.html',
  styleUrls: ['./inactivity-timer.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class InactivityTimerComponent implements OnInit, OnDestroy, AfterViewChecked {
  imgSrc = environment.imgBasURL;
  minutesDisplay = 0;
  secondsDisplay = 0;

  endTime: number;
  minutes: any;
  second: number;

  timerSeconds: number;
  minInSeconds: number;
  noMinutes: number;
  sessionConstant: string;
  stillHere: string = null;
  stillHereCondition: string = '';
  Onedigit: boolean = false;
  RefreshTokenMinuteBeforeExpire: any;
  url: any;
  CurrentTime: any;
  UserDetailList: any;
  timeLeft: number;
  interval;

  @ViewChild('TimerOpen') TimerOpen: ElementRef;
  subscription: Subscription;
  timeOutSeconds: number = 600;

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private cfr: ChangeDetectorRef,
    private bnIdle: BnNgIdleService,
    private authService: AuthService
  ) {
    this.url = environment.configUrl;
    this.timerSeconds = this.url['timerSeconds'];
    this.minInSeconds = this.url['minuteInSeconds'];
    this.noMinutes = this.url['noMinutes'];
    this.sessionConstant = this.url['sessionConstant'];
    this.stillHereCondition = this.url['stillHereCondition'];
    config.backdrop = 'static';
    config.keyboard = false;

    if (environment.configUrl['TimeoutPopup'] !== undefined) {
      this.timeOutSeconds = environment.configUrl['TimeoutPopup'];
    }
  }

  ngOnInit(): void {
    this.subscription = this.bnIdle.startWatching(this.timeOutSeconds).subscribe((isTimedOut: boolean) => {
      console.log("startWatching 1: " + isTimedOut);
      if (isTimedOut) {
        let token = this.authService.GetUserToken();
        if (token) {
          console.log("startWatching 2: " + isTimedOut);
          this.TimerOpen?.nativeElement.click();
          this.interval = this.startTimer();
        }
      }      
    });
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    sessionStorage.clear();
  }

  ngAfterViewChecked() {
    this.cfr.detectChanges();
  }

  open(content: any) {
    this.modalService.open(content, { windowClass: 'dialog-centered' });
  }

  startTimer() {
    this.timeLeft = this.timerSeconds;
    return setInterval(() => {
      if (this.timeLeft < 0) {
        let token = this.authService.GetUserToken();
        if (token) {
          clearInterval(this.interval);
          delete this.timeLeft;
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.setItem('sessionTimeout', this.sessionConstant);
          this.modalService.dismissAll();
          this.router.navigate(['/login']);
        }
        return;
      }

      if (this.timeLeft >= this.minInSeconds) {
        this.minutes = (this.timeLeft / this.minInSeconds).toString().split('.');
        this.minutes = +this.minutes[0];
        this.second = this.timeLeft % this.minInSeconds;
        let secondlength = this.second.toString().length;
        if (secondlength == 1) {
          this.Onedigit = true;
        } else {
          this.Onedigit = false;
        }
      }
      else {
        this.minutes = this.noMinutes;
        this.second = this.timeLeft;
        if (this.second.toString().length == 1) {
          this.Onedigit = true;
        } else {
          this.Onedigit = false;
        }
      }

      this.timeLeft = this.timeLeft - 1;
    }, 1000);

  }

  Refreshtoken() {
    this.minutes = this.second = 0;
    clearInterval(this.interval);
    delete this.timeLeft;
    delete this.interval;    
    this.bnIdle.resetTimer();
    this.modalService.dismissAll();
  }

}

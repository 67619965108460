import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, } from 'rxjs';
import { CacheService } from '../../Services/cache.service'
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs-compat/operators/tap';
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  UserDetailList: any;
  constructor(private router: Router, private cache: CacheService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.set('domainKey', environment.configUrl.DomainKey),
    });
    this.UserDetailList = JSON.parse(sessionStorage.getItem('UDList'));
    if (this.UserDetailList != null && req.url.search('/energy') < 0 && req.url.search('/AddAccount') < 0) {
      let token = this.UserDetailList?.idToken.replace('Bearer ','');
      req = req.clone({
        headers: req.headers.set('authorization', 'Bearer ' + token),//.set("dob",this.UserDetailList?.dob)
      });
    }
    else {
      console.log('no Token for request - ' + JSON.stringify(req.url));
    }
    const cachedata = this.cache.get(req);
    if (req.method === 'POST' || req.method === 'PUT') {
      this.shiftDates(req.body);
    }
    if (cachedata) {
      return of(cachedata);
    }

    return next.handle(req).pipe(
      tap(
        succ => {
          if (succ instanceof HttpResponse) {
            this.cache.put(req, succ);
          }
          localStorage.removeItem("IsRedirectByIntercept")
        },
        (err: any) => {

          if (!err.url.search('AccountsRefresh')) {
            console.log('Actual error: ' + JSON.stringify(err));
            this.router.navigate(['/errorpage']);
          }
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401 && err.status !== 403) {
              return;
            }

            if(!localStorage.getItem("IsRedirectByIntercept"))
            {
              let loc=document.location.href.replace(document.location.origin,'');
              this.router.navigate(['/login'],{queryParams:{returnUrl:loc}})
            }
            localStorage.setItem("IsRedirectByIntercept","1");
        }
      }
      ));

  }
  shiftDates(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
          , value.getSeconds()));
      } else if (typeof value === 'object') {
        this.shiftDates(value);
      }
    }
  }
  //executing the HttpRequest Parallel
  handleHttpRequest(handler: HttpHandler, req: HttpRequest<any>) {
    handler.handle(req).subscribe(succ => {
      if (succ instanceof HttpResponse) {
        this.cache.put(req, succ);
      }
    })
  }
}

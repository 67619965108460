import { ActivateAccountComponent } from './Pages/login/activate-account/activate-account.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Extras/AuthGuard/auth.guard';
import { ErrorpageSignupComponent } from './Pages/errorpage-signup/errorpage-signup.component';
import { ErrorpageComponent } from './Pages/errorpage/errorpage.component';
import { LoginComponent } from './Pages/login/login/login.component';
const routes: Routes = [
  { path: '*', redirectTo: '/login', pathMatch: 'full' as 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' as 'full' },
  { path: 'login', component:LoginComponent },
  { path: 'errorpage', component:ErrorpageComponent },
  { path: 'activate-account', component:ActivateAccountComponent },
  { path: 'password-change-success', loadChildren: () => import('./Pages/login/password-change-success/password-change-success.module').then(m=>m.PasswordChangeSuccessModule) },
  { path: 'forgot-pass', loadChildren:() => import('./Pages/login/forgot.module').then(m => m.ForgotModule) },
  { path: 'send-reset-password-link', loadChildren:() => import('./Pages/login/send-reset-password-link/send-reset-password-link.module').then(m=>m.SendResetPasswordLinkModule) },
  { path: 'forgot-reset-password', loadChildren: () => import('./Pages/login/forgot-reset-password/forgot-reset-password.module').then(m=>m.ForgotResetPasswordModule) },
  { path: 'password-reset-success', loadChildren: () => import('./Pages/login/password-reset-success/password-reset-success.module').then(m=>m.PasswordResetSuccessModule) },
  { path: 'change-password', loadChildren: ()=>import('./Pages/login/change-password/change-password.module').then(m=>m.ChangePasswordModule), canActivate: [AuthGuard] },
  { path: 'dashboard', loadChildren: ()=>import('./Pages/dashboard/dashboard.module').then(m=>m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'sign-up-create-password', loadChildren:()=>import('./Pages/sign-up/sign-up-create-password/sign-up-create-password.module').then(m=>m.SignUpCreatePasswordModule) },
  { path: 'sign-up-personal-details', loadChildren:()=>import ('./Pages/sign-up/sign-up-personal-details/sign-up-personal-details.module').then(m=>m.SignUpPersonalDetailsModule) },
  { path: 'add-credit-profile', loadChildren:()=>import ('./Pages/sign-up/add-credit-profile/add-credit-profile.module').then(m=>m.AddCreditProfileModule), canActivate: [AuthGuard] },
  { path: 'connect-marketplace', loadChildren:()=>import('./Pages/marketplace/connect-to-market/connect-to-market.module').then(m=>m.ConnectToMarketplaceModule), canActivate: [AuthGuard] },
  {path:'access-blocked',loadChildren:()=>import('./Pages/login/access-blocked/access-blocked.module').then(m=>m.AccessBlockedLoginModule)},
  {path:'access-blocked-signup',loadChildren:()=>import('./Pages/sign-up/access-blocked-signup/access-blocked-signup.module').then(m=>m.AccessBlockedSignupModule)},
  {path:'articledetails',loadChildren:()=>import('./Pages/articles-full-page/articles-full-page.module').then(m=>m.ArticleFullPageModule), canActivate: [AuthGuard] },
  { path: 'accounts-overview', loadChildren:()=>import( './Pages/accounts-overview/accounts-overview.module').then(m=>m.AccountsOverviewModule), canActivate: [AuthGuard] },
  { path: 'educate', loadChildren:()=>import( './Pages/educate/educate.module').then(m=>m.EducateModule), canActivate: [AuthGuard] },
  { path: 'credit-detail', loadChildren: ()=>import('./Pages/credit-details/credit-details.module').then(m=>m.CreditDetailsModule), canActivate: [AuthGuard] },
  { path: 'credit-report', loadChildren: ()=>import('./Pages/credit-score/credit-score.module').then(m=>m.CreditScoreModule), canActivate: [AuthGuard] },
  { path: 'marketplace', loadChildren:()=>import('./Pages/marketplace/marketplace.module').then(m=>m.MarketplaceModule), canActivate: [AuthGuard] },
  { path: 'notification', loadChildren: ()=>import('./Pages/notification-mobile-view/notification.module').then(m=>m.NotificationModule), canActivate: [AuthGuard] },
  { path: 'sign-up-address-verify', loadChildren:()=>import('./Pages/sign-up/sign-up-address-verify/sign-up-address-verify.module').then(m=>m.SignUpAddressVerifyModule), canActivate: [AuthGuard] },
  { path: 'identity-check-kba', loadChildren:()=>import('./Pages/sign-up/identity-check-kba/identity-check-kba.module').then(m=>m.IdentityCheckKbaModule), canActivate: [AuthGuard] },
  { path: 'identity-check-failed-error', loadChildren:()=>import('./Pages/sign-up/identity-check-failed-error/identity-check-failed-error.module').then(m=>m.IdentityCheckFailedErrorModule), canActivate: [AuthGuard] },
  { path: 'identity-check-error', loadChildren:()=>import('./Pages/sign-up/identity-check-error/identity-check-error.module').then(m=>m.IdentityCheckErrorModule), canActivate: [AuthGuard] },
  { path: 'identity-check-credit-report-data-failed', loadChildren:()=>import('./Pages/sign-up/identity-check-credit-report-data-failed/identity-check-credit-report-data-failed.module').then(m=>m.IdentityCheckCreditReportDataFailedModule), canActivate: [AuthGuard] },
  { path: 'add-bank-wizard', loadChildren:()=>import('./Pages/sign-up/add-bank-wizard/add-bank-wizard.module').then(m=>m.AddBankWizardModule), canActivate: [AuthGuard] },
  { path: 'marketplace/energy-switching/get-quote', loadChildren:()=>import('./Pages/externalEnergy/current-details/current-details.module').then(m=>m.CurrentDetailsModule), canActivate: [AuthGuard] },
  { path: 'marketplace/energy-switching/energy-switch-offer', loadChildren:()=>import('./Pages/externalEnergy/offer/offer.module').then(m=>m.OfferModule), canActivate: [AuthGuard] },
  { path: 'marketplace/energy-switching/energy-switch-signup/:id',  loadChildren:()=>import('./Pages/externalEnergy/energy-sign-up/energy-sign-up.module').then(m=>m.EnergySignUpModule), canActivate: [AuthGuard] },
  { path: 'marketplace/energy-switching/energy-switch-success',  loadChildren:()=>import('./Pages/externalEnergy/energy-switch-success/energy-switch-success.module').then(m=>m.EnergySwitchSuccessModule), canActivate: [AuthGuard] },
  { path: 'marketplace/energy-switching-suspended', loadChildren:()=>import('./Pages/externalEnergy/energy-suspended/energy-suspended.module').then(m=>m.EnergySuspendedModule), canActivate: [AuthGuard] },
  { path: 'my-profile', loadChildren:()=>import('./Pages/my-profile/profile.module').then(m=>m.ProfileModule), canActivate: [AuthGuard] },
  { path: 'callback', loadChildren:()=>import('./Pages/callback/callback.module').then(m=>m.CallBackModule), canActivate: [AuthGuard] },
  { path: 'terms-and-conditions', loadChildren:()=>import('./Pages/terms-and-conditions/terms-and-conditions.module').then(m=>m.TermsAndConditionsModule) },
  { path: 'privacy-policy', loadChildren:()=>import('./Pages/privacy-policy/privacy-policy.module').then(m=>m.PrivacyPolicyModule) },
  { path: 'trackreferral', loadChildren: ()=>import('./Pages/trackreferral/trackreferral.module').then(m=>m.TrackreferralModule) },
  { path: 'errorpage-signup', component:ErrorpageSignupComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

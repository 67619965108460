<div class="login-page-conatiner">
<div id="login-wrapper-logo">
<img id="login-logo" class="img-fluid" alt="image not found" src="{{imgSrc}}blossm_logo.svg" width="auto" height="auto"  />
</div>
<div *ngIf="!loader">
<div class="login-features-name">Hello {{name}}. Activate your account</div>
<form [formGroup]="form">
<div id="login-form-container">
<div class="text-name">Username</div>
<input type="text" class="form-control input-field" value={{user.username}} formControlName="Username" placeholder="Enter username">

<div class="text-name">Set Password</div>
<input type={{type}} class="form-control input-field"  placeholder="" formControlName="Password" (keyup)="OnPasswordPattern($event.target.value)">
<em  class="eye-icon hover-cursor" (click)="ShowHidePass()">
<img id="open-eye" class="img-fluid" src="{{img_src}}" alt="image not found" width="auto" height="auto" ></em>  


<div>
<ul class="validation-requirmet">
        <li [ngClass]="IsUpperLowerCase ? 'green ' : 'cross'">
            An uppercase & lowercase character
        </li>
        <li [ngClass]="IsNumber ? 'green ' : 'cross'">
            Please provide at least one number
        </li>

        <li [ngClass]="Islength ? 'green ' : 'cross'">
            Password must be between 8-20 characters
        </li>
    </ul>
</div>
<div id="btn-sign-in-wrapper">
<button type="button" [disabled]="!form.valid" class="btn sg-btn" (click)="onSubmit()">
<div class="container" *ngIf="IsImageLoaded">
<div style="margin:1% auto;"><div class="spinner-border custom-spinner-border" role="status"></div>
<span *ngIf="!IsImageLoaded">Activate</span>
</div></div>
</button>
</div>

</div></form>
</div>
<div id="loader-container" *ngIf="loader">
<div class="text-center">
<div class="spinner-border-sm spinner-border spinner-setup" role="status"></div>
<div class="textloader">{{message}}</div>
</div>
</div>


</div>
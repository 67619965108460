<div class="login-page-conatiner">
    <div *ngIf="!deactiveUser" id="login-wrapper-logo">
        <img id="login-logo" class="img-fluid" alt="image not found" src="{{imgSrc}}blossm_logo.svg" width="auto" height="auto">
    </div>
    <div *ngIf="deactiveUser" class="alert-popup-box">
        <div class="d-flex">
          <div class="box-close-x"></div>
          <div><img class="img-close-x" src="{{imgSrc}}alert.svg" alt="image not found"></div>
          <div class="alert-text-css">You will not be able to access Blossm application because your account is marked inactive by your employer.</div>
          <button type="button" (click)="close()" class="close-x"><img src="{{imgSrc}}Vector-btn.svg"></button>
        </div>
        </div>
    <div class="container">
        <div class="justify-content-center">
            <div class="login-width">
                <div *ngIf="!deactiveUser" id="login-in-logo">
                    <img id="login-logo" class="img-fluid" alt="image not found" src="{{imgSrc}}blossm_logo.svg" width="auto" height="auto">
                </div>
                <div class="login-features-name"> Sign in to your account</div>
                <form [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
                    <div id="login-form-conatiner">
                        <!-- field one -->
                        <div class="form-group">
                            <input type="text" formControlName="Email" [(ngModel)]="userModel.username"
                                [readonly]="isFormDisabled ? true : null" maxlength="60" placeholder="Email address"
                                class="form-control text-box-h" (focusout)="checkValidEmail($event)"
                                (focus)="IsrequiredMsg=false" (focus)="IsEmailNotValid=false"
                                (focus)="show_error_message=false"
                                [ngClass]="{'is-invalid':getAllFormControle.Email.touched && getAllFormControle.Email.errors}">

                            <div class="text-danger">
                                <div *ngIf="IsrequiredMsg || IsEmailNotValid">Please enter a valid email address</div>
                            </div>
                        </div>
                        <!-- field second -->
                        <div class="form-group">
                            <input type="{{type}}" formControlName="Password" [(ngModel)]="userModel.password"
                                [readonly]="isFormDisabled ? true : null" autocomplete="new-password"
                                onKeyPress="if(this.value.length==32) return false;"
                                (focusout)="checkValidPassword($event)" (focus)="IsPasswordRequiredMsg=false"
                                (focus)="IsPasswordLengthValid=false" (focus)="show_error_message=false"
                                [ngClass]="{'is-invalid':getAllFormControle.Password.touched && getAllFormControle.Password.errors}"
                                placeholder="Password" class="form-control text-box-h">
                            <em class="eye-icon hover-cursor" (click)="ShowHidePass()">
                                <img id="open-eye" class="img-fluid" src="{{img_src}}" alt="image not found"
                                    width="auto" height="auto"></em>

                            <div class="text-danger">
                                <div *ngIf="IsPasswordRequiredMsg || IsPasswordLengthValid">Please enter a valid
                                    password</div>
                            </div>
                            <div class="text-danger">
                                <div *ngIf="show_error_message">{{result}}</div>
                            </div>
                        </div>
                        <!-- requirmet -->

                        <div class="row alert alert-success hover-cursor sg-font-m-b sg-txt-green sg-font-size-13 ml-0 mr-0"
                            *ngIf="IsReturnTLCallback"> Please login to continue data fatching process. </div>
                        <!-- Remember my email -->
                        <div class="clearfix"></div>
                        <div id="remaimber-forget-wrapper">
                            <div class="d-flex justify-content-between">
                                <div><label class="container-01"> <span class="remember-me-txt">Remember my email</span>
                                        <input type="checkbox" formControlName="checkbox" [(ngModel)]="savecredentials"
                                            [checked]="true" (change)="onCheckboxChange($event)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <!-- forget password -->
                                <div class="forgetpass-txt" routerLink="/forgot-pass">Forgot Password?</div>
                            </div>
                        </div>
                        <!-- Login Button -->
                        <div id="btn-sign-in-login">
                            <button [disabled]="(LoginForm.invalid)" class="login-btn-login">
                                <div class="container" *ngIf="IsImageLoaded">
                                    <div style="margin:1% auto;">
                                        <div class="spinner-border custom-spinner-border" role="status"></div>
                                    </div>
                                </div>
                                <span *ngIf="!IsImageLoaded">Sign In </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <input style="width: 438px;" type="text" id="sar_prefs" type="hidden" name="payloadArea" readonly /><br>
    <input style="width: 438px;" type="text" id="user_prefs2" type="hidden" name="hdmArea" readonly />
</div>


<!-- Modal For your security we have logged you out automatically -->
<button id="openModal" #SessionModal [hidden]="true" data-toggle="modal" data-target=".bd-exampl-modal-lg3"></button>
<div class="modal fade bd-exampl-modal-lg3" id="timerModel" data-backdrop="static" data-keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="timerModelLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="img-icon text-center">
                    <img class="img-fluid" src="{{imgSrc}}iconmodeltimer.svg" alt="image not found" width="auto"
                        height="auto">
                </div>
                <div class="img-icon">
                    <div class="info-txt">For your security we have logged you out automatically.</div>
                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-ok" (click)="modelClosed();" data-dismiss="modal">Okay</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Logout popup -->
<button id="openLogoutModal" #LogoutModal [hidden]="true" data-toggle="modal"
    data-target=".bd-exampl-modal-lg4"></button>
<div class="modal fade bd-exampl-modal-lg4" id="logoutsuccess" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-body-logout">
                <div class="logout-modal-img-container">
                    <img src="{{imgSrc}}logoutcheck.svg" alt="image not found" width="auto" height="auto">
                </div>
                <div class="text-center">
                    <div class="logoutmodaltxt">You have been successfully logged out</div>
                </div>
                <div class="text-center">
                    <button [hidden]="true" type="button" #closeBtn data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Important Information popup -->
<div class="modal fade bd-exampl-modal-lg4" id="Outofcountry" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-out" role="document">
        <div class="modal-content">
            <div class="close-icon-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="{{imgSrc}}close_new_cross.svg" class="close-new-icon" width="auto" height="auto"
                        alt="image not found"></button>
            </div>
            <div class="modal-body modal-body-out">
                <div class="logout-modal-img-container">
                    <img class="img-fluid width-img" src="{{imgSrc}}error-message-icon.svg" width="auto" height="auto"
                        alt="image not found">
                </div>
                <div class="text-center">
                    <div class="logoutmodaltxt">Important Information</div>
                </div>
                <div id="block-info-txt">We detected that you’re accessing Ooki
                    from outside the United Kingdom. To keep our users data safe, we only allow sign up from inside the
                    UK. Please check in when you’re back.</div>
            </div>
        </div>
    </div>
</div>
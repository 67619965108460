import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AppCookieService {
  constructor(private _cookieService: CookieService) { }
  getByKey(key: string) {
    return this._cookieService.get(key);
  }
  setByKey(key: string, value: any) {
    this._cookieService.set(key, JSON.stringify(value));
  }
  removeByKey(key: string) {
    this._cookieService.delete(key);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailVerify,PostCode, SignUpRequest, TariffRequest, User } from '../Models/EnergyLinxProviderDetails';
import { StorageService } from '../Services/StorageService';
import { environment } from '../../environments/environment';


@Injectable()
export class EnergyLinxService {
  [x: string]: any;

  constructor(private _httpClient: HttpClient,  private storageService: StorageService) { }

  userInfo = new User('', '');
  url = environment.configUrl;
  readonly energyUrl = this.url['EnergyUrl'];
  readonly userName = this.url['EnergyUserName'];
  readonly password = this.url['EnergyUserPassword'];
  readonly clientId = this.url['ClientId'];

   readonly   rootUrl = environment.apiUrl;
   readonly baseUrl = this.url['BaseUrl'];


  getAuthToken() {
    this.userInfo.username = this.userName;
    this.userInfo.password = this.password;
    this.storageService.setData('clientId', this.clientId);

    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });
    return this._httpClient.post(this.energyUrl + '/AuthToken', this.userInfo, { headers: requestheader });
  }


  getAddress(postcode: PostCode, token: any) {
    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });
    requestheader = requestheader.append('Access-Control-Allow-Origin', '*');
    let strUrl = this.energyUrl + '/address';
    strUrl += '?postCode=' + postcode.postcode;
    return this._httpClient.get(strUrl, { headers: requestheader });
  }

  getTariffData(tariffRequest: TariffRequest) {
    let requestheader = new HttpHeaders({ 'content-type': 'application/json' });
    requestheader = requestheader.append('Accept', 'application/json');
    requestheader = requestheader.append('clientId', tariffRequest.clientId);
    let strUrl = this.energyUrl + '/tariffs';
    strUrl += '?size=' + tariffRequest.size;
    strUrl += '&postCode=' + tariffRequest.postCode;


    if (tariffRequest.buildingNumber == '') {
      let emptyString = ' ';
      strUrl += '&buildingNumber=' + emptyString;
    } else {
      strUrl += '&buildingNumber=' + tariffRequest.buildingNumber;
    }

    if (tariffRequest.buildingName != '') {
      strUrl += '&buildingName=' + tariffRequest.buildingName;
    }

    strUrl += '&source=' + tariffRequest.source;
    strUrl += '&campaign=' + tariffRequest.campaign;
    strUrl += '&medium=' + tariffRequest.medium;
    strUrl += '&keyword=' + tariffRequest.keyword;
    strUrl += '&content=' + tariffRequest.content;
    strUrl += '&totalSpend=' + tariffRequest.expenditure;
    strUrl += '&propertySize=' + tariffRequest.propertySize;
    strUrl += '&interval=' + tariffRequest.interval;
    strUrl += '&addressLine1=' + tariffRequest.address_line1;
    strUrl += '&addressLine2=' + tariffRequest.address_line2;
    strUrl += '&city=' + tariffRequest.town_or_city;
    return this._httpClient.get(strUrl, { headers: requestheader });
  }

  getByKey(key: string) {
    return this._cookieService.get(key);
  }
  setByKey(key: string, value: any) {
    this._cookieService.set(key, JSON.stringify(value));
  }
  signUpTariffRequest(signUpRequest: SignUpRequest) {
    let requestheader = new HttpHeaders({ 'Content-Type': 'application/json' });
    requestheader = requestheader.append('Accept', 'application/json');
    requestheader = requestheader.append('clientId', signUpRequest.clientId);
    let tariffUrl = this.energyUrl + '/tariffs';
    signUpRequest.customer.title = 'Mr.';
    return this._httpClient.post(tariffUrl, signUpRequest, { headers: requestheader });
  }

  EmailNotification(email: EmailVerify) {
    let requestheader = new HttpHeaders({ 'Content-Type': 'application/json' });
    let EmailNotificationurl = this.energyUrl + '/EmailNotification';
    return this._httpClient.post(EmailNotificationurl, email, { headers: requestheader });
  }

  GetAddressDetailByUserId() {
    return this._httpClient.post<any>(this.rootUrl + '/user/AddressDetail', {});
  }

  GetAllAcountType() {
    return this._httpClient.post<any>(this.rootUrl + '/accountoverview/GetUserAccountsAllType', { });
  }
}

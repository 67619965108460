import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/Models/User';
import { CryptographyService } from 'src/app/Services/cryptography.service';
import { ScoreGeniusService } from 'src/app/Services/score-genius.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-activate-account-page',
  templateUrl: './activate-account-page.component.html',
  styleUrls: ['./activate-account-page.component.css']
})
export class ActivateAccountPageComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  img_src = this.imgSrc + 'eye.svg';
  show = false;
  type = 'password';
  Islength = false;
  IsUpperLowerCase = false;
  IsNumber = false;
  name: string;
  userEmail: string;
  user = new User('','');
  userModel = new User('','');
  form: UntypedFormGroup;
  loader: boolean = true;
  message:string='We are checking your request';
  submitted = false;
  IsImageLoaded: boolean = false;
  IsSymbol = false;
  RequirmentPassword: boolean = false;
  P_pattern = '';

  constructor(private activatedRoute: ActivatedRoute, private crypto_service: CryptographyService,private fb: UntypedFormBuilder,private _services: ScoreGeniusService,private router: Router) { 
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['name']) {
        this.name = params['name'];
      }
      if (params['refid']) {
        this.userEmail = params['refid'].replace(/\%20/g,"+").replace(/ /g,"+");
        this.user.username = this.crypto_service.decrypt(this.userEmail);
      }
    });
  }
  
  OnPasswordPattern(PatternPass): any {
    this.P_pattern = PatternPass;
    if (this.P_pattern.search(/(?=.*?[a-z])(?=.*?[A-Z])/) != -1) {
      this.IsUpperLowerCase = true;
    } else {
      this.IsUpperLowerCase = false;
    }
    if (this.P_pattern.search(/\d/) != -1) {
      this.IsNumber = true;
    } else {
      this.IsNumber = false;
    }
    if (this.P_pattern.search(/[!@#$%^&*(),.?":{}|<>]/) != -1) {
      this.IsSymbol = true;
    } else {
      this.IsSymbol = false;
    }
    if (this.P_pattern.toString().length >= 8) {
      this.Islength = true;
    } else {
      this.Islength = false;
    }
  }

  ShowHidePass() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.img_src = this.imgSrc + 'eye-green.svg';
    } else {
      this.type = 'password';
      this.img_src = this.imgSrc + 'eye.svg';
    }
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      Username: [{value:'', disabled: true}, [Validators.required, Validators.email, Validators.pattern(/^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/)]],
      Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32), Validators.pattern(/(?=.*?[a-z])(?=.*?[A-Z])/), Validators.pattern(/\d/)]]
     });

    this.CheckUser(this.userEmail);
  }

  CheckUser(username: string) {
     this._services.CheckUser(username).subscribe((res: any) => {
      if (res != null && res.statusCode == 0) {
        if (res.message =='NewUser') {
          this.loader = false;
        } else if (res.message == 'UserExist' ) {
          this.message = 'Your account already exist. Redirecting to login page.';
          setTimeout(()=>{                         
            this.router.navigate(['/login']);
        }, 2000);
        }
      }
    });
  }
get Password() {
    return this.form.controls;
  }

  onSubmit() {
   this.submitted = true;
   this.IsImageLoaded= true;
    if (this.form.invalid) {
      this.IsImageLoaded= false;
      return;
    }

    if (!this.IsUpperLowerCase || !this.IsNumber || !this.IsSymbol || !this.Islength) {
      this.RequirmentPassword = true;
      return;
    }
    this.userModel.password = this.form.get('Password').value;
    this.userModel.username = this.userEmail;
    this._services.SaveUserInOoki(this.user).subscribe((res: any) => {
      if (res != null && res.statusCode ==0) {
        this.router.navigate(['/login']);
      } else {
        console.log('Error found');
      }
    });
  }
}

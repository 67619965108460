import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

 // _userActionOccured: Subject<void> = new Subject();
 // get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }

  //IsTimerOpenPopup$ = new Subject<boolean>();

  // notifyUserAction() {
  //   this._userActionOccured.next();
  // }

  loginUser() {
    console.log('user login');
  }

  logOutUser() {
    console.log('user logout');
  }

  GetUserToken(): string{
    let userDetail = JSON.parse(sessionStorage.getItem('UDList'));
    if (userDetail != null){
      return userDetail?.idToken.replace('Bearer ','');
    }
    else{
      return null;
    }
  }

}

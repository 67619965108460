import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://25bd133aa146486bad3813e6047bb32b@o263113.ingest.sentry.io/5578781',
      environment: environment.name
    });
  }
  handleError(error) {
    // It handle only production error on sentry
    if (environment.name == 'Prod' || environment.name == 'Dev' || environment.name == 'QA') {
      Sentry.captureException(environment.name + ':' + error.originalError || error);
    } 
    throw error;
  }
}

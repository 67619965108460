import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-errorpage-signup',
  templateUrl: './errorpage-signup.component.html',
  styleUrls: ['./errorpage-signup.component.css']
})
export class ErrorpageSignupComponent implements OnInit {
  imgSrc = environment.imgBasURL;
  constructor(private route: Router) { }

  ngOnInit() {
    //Code Initialization Here
  }

  GoLogin() {
    this.route.navigate(['login']);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ScoreGeniusService } from '../../../Services/score-genius.service';
import { TruelayerService } from '../../../Services/truelayer.service';
import { TrueLayerETLRequest } from '../../../Models/TrueLayer';
import { User, CheckDeviceRequest } from '../../../Models/User';
import { AppCookieService } from '../../../Services/CookieService';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { ApiLogin } from '../../../Models/ApiLogin';
import { CacheService } from '../../../Services/cache.service';
import { TooltipDescription } from '../../../Models/Tooltip';
import * as CryptoJS from 'crypto-js';
import { CMSIntegrationService } from 'src/app/Services/cmsintegration.service';
declare let Deviceinitiate: Function;

declare let GetPayLoad: Function;
declare let sar_prefs;
declare let user_prefs2;

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  imgSrc = environment.imgBasURL;
  LoginForm: UntypedFormGroup;
  userModel = new ApiLogin('', '', '', '');
  apiloginCredential: User;
  submitted = false;
  show_error_message: boolean = false;
  isChecked: boolean = false;
  result: string = '';
  show = false;
  type = 'password';
  img_src = this.imgSrc + 'eye.svg';
  Component1Variable: string = 'Component1VariableSent';
  loading: boolean = true;
  model: any = {};
  returnUrl: string;
  savecredentials = false;
  trueLayerETLRequest = new TrueLayerETLRequest('', []);
  IsReturnTLCallback: boolean = false;
  @ViewChild('SessionModal') SessionModal: ElementRef;
  @ViewChild('LogoutModal') LogoutModal: ElementRef;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime: number;
  minutes: any;
  second: number;
  isFormDisabled: boolean = false;
  timerSeconds: number;
  minInSeconds: number;
  noMinutes: number;
  interval: number;
  stillHere: string = null;
  stillHereCondition: string;
  timerSubscription: Subscription;
  deviceCheck = new CheckDeviceRequest(0, '', '');
  CognitoUrl: string;
  IsImageLoaded: boolean = false;
  IsrequiredMsg: boolean = false;
  IsEmailNotValid: boolean = false;
  IsPasswordRequiredMsg: boolean = false;
  IsPasswordLengthValid: boolean = false;
  pageNavigation: string;
  TooltipDescriptionModal = new TooltipDescription('', '', '', '', '', '');
  UserDetailList: any;
  EncryptionText: string = 'publicwebsite-Ooki';
  deactiveUser: boolean = false;

  url = environment.configUrl;
  readonly rootUrl = environment.apiUrl;
  readonly fbUrl = this.url['fbUrl'];
  readonly googleUrl = this.url['googleUrl'];
  readonly baseUrl = this.url['BaseUrl'];

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder, 
    private _services: ScoreGeniusService,    
    private _truelayerService: TruelayerService,
    private cookieService: CookieService,
    private cookie_Service: AppCookieService,
    private cache: CacheService,
    private _CMSservices: CMSIntegrationService) {

    if (sessionStorage.getItem('sessionTimeout')) {
      let sessionConstant = sessionStorage.getItem('sessionTimeout');
      sessionStorage.clear();
      sessionStorage.setItem('sessionTimeout', sessionConstant);
    }
    else {
      sessionStorage.clear();
    }

    cookieService.delete('username');
    this.endTime = this.url['AllocationSlidingSessionTimeout'];
    this.timerSeconds = this.url['logoutSuccessSeconds'];
    this.minInSeconds = this.url['minuteInSeconds'];
    this.noMinutes = this.url['noMinutes'];
    this.interval = this.url['intervalTime'];
    this.stillHereCondition = this.url['stillHereCondition'];
    this.stillHere = null;

    this.activatedRoute.queryParams.subscribe(result => {
      if (result["ref"] && result["email"]) {
        let ref = CryptoJS.AES.decrypt(decodeURIComponent(result["ref"]), this.EncryptionText);
        let refValue = ref.toString(CryptoJS.enc.Utf8);
        let email = CryptoJS.AES.decrypt(decodeURIComponent(result["email"]), this.EncryptionText);
        let emailValue = email.toString(CryptoJS.enc.Utf8);
        if (refValue == 'public')
          this.cookieService.set('emailid', emailValue);
      }
    })

    this.route.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
    if (sessionStorage.getItem('CallBackIdentity') != null) {
      let callbackResponse = this._services.getAWSCallBackMessage();
      this.show_error_message = true;
      this.result = callbackResponse;
      sessionStorage.removeItem('CallBackIdentity');
    }
    if (this.returnUrl != undefined && this.returnUrl != null && this.returnUrl.includes('callback')) {
      this.IsReturnTLCallback = true;
    }

    this.show = false;
    this.IsImageLoaded = false;
    this.GetArticle();
  }  

  ngAfterViewInit(): void {
    Deviceinitiate();
    if (sessionStorage.getItem('sessionLogout')) {
      this.LogoutSuccessClose();
    }
    else if (sessionStorage.getItem('sessionTimeout')) {
      this.checkModal();
    }
  }

  ngOnInit() {
    this.cache.clear();
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    sessionStorage.removeItem('UserName');
    sessionStorage.removeItem('FName');
    this.userModel.username = this.cookieService.get('emailid');
    this.LoginForm = this.fb.group({
      Email: ['', [Validators.required, Validators.pattern(/^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/)]],
      Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32)]],
      checkbox: [false]
    });

    // will use in feature    
    this.rememberme();
  }

  rememberme() {
    if (this.cookieService.get('emailid') != '') {
      this.savecredentials = true;
    } else {
      this.cookieService.set('rememberMeStatus', 'true');
      this.savecredentials = false;
    }
  }

  // Pass Data in AWSCallback
  passDataFaceBook() {
    sessionStorage.removeItem('CallBackIdentity');
    sessionStorage.setItem('CallBackIdentity', 'SignIn');
    this.CognitoUrl = this.fbUrl;
    window.location.href = this.CognitoUrl;
  }

  passDataGoogle() {
    sessionStorage.removeItem('CallBackIdentity');
    sessionStorage.setItem('CallBackIdentity', 'SignIn');
    this.CognitoUrl = this.googleUrl;
    window.location.href = this.CognitoUrl;
  }

  ShowHidePass() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
      this.img_src = this.imgSrc + 'eye-green.svg';
    } else {
      this.type = 'password';
      this.img_src = this.imgSrc + 'eye.svg';
    }
  }

  get getAllFormControle() {
    return this.LoginForm.controls;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  onSubmit() {
    this.deactiveUser = false;
    this.show_error_message = false;
    if (this.isFormDisabled) {
      return;
    }
    // needs to  test
    this.cookie_Service.removeByKey('userProfile');
    sessionStorage.removeItem('UserName');
    sessionStorage.removeItem('FName');
    this.IsImageLoaded = true;
    this.submitted = true;
    if (this.LoginForm.invalid) {
      this.IsImageLoaded = false;
      return;
    }

    if (this.cookieService.get('rememberMeStatus') == 'true' && this.LoginForm.valid && this.submitted) {
      this.cookieService.set('emailid', this.getAllFormControle.Email.value);
    } else {
      this.cookieService.delete('emailid');
    }
    GetPayLoad();
    this.userModel.hdmdata = user_prefs2.value;
    this.userModel.payload = sar_prefs.value;
    sessionStorage.setItem('payloadArea', sar_prefs.value);
    sessionStorage.setItem('hdmArea', user_prefs2.value);
    this.isFormDisabled = true;
    this.show_error_message = false;
    this._services.userloginAuth(this.userModel).subscribe((res: any) => {
      this.isFormDisabled = false;
      if (res.data != null) {
        if (res.data.errorCode == 0) {
          this.show_error_message = false;
          sessionStorage.setItem('UDList', JSON.stringify(res.data));
          localStorage.setItem('UDListT', JSON.stringify(res.data));
          this.cookieService.set('callbackIdToken', res.data.idToken);
          this.cookieService.set('callbackUserName', res.data.email);
          this.cookieService.set('callbackFName', res.data.fName);
          this.cookieService.set('callbackLName', res.data.lName);
          this.cookieService.set('callbackEmail', res.data.email);
          this.cookieService.set('callbackMobile', res.data.mobile);
          this.cookieService.set('callbackTitle', res.data.title);
          this.cookieService.set('callbackProfileImage', res.data.profileImage);          
          sessionStorage.setItem("IsEl", "True");
          let accConnected = res.data["accConnected"];
          sessionStorage.setItem("AccountStatus", accConnected);

          if (accConnected) {
            this._refreshAccount();
          }

          if (res.data.redirectStatus != null) {
            if (res.data.redirectStatus == 2) {              
              if (this.returnUrl == '/') {
                // lastStep 2 = Password page
                // lastStep 3 = Credit profile continue page
                // lastStep 4 = DOB page
                // lastStep 5 = Address page
                // lastStep 6 = Additional page
                // lastStep 7 = KBA page
                // lastStep 81 = Pending status
                // lastStep 85 = Exception on KBA like InternalServerError
                // lastStep 83 = notverified
                if ((res.data.lastStep >= 2 && res.data.lastStep < 9 && res.data.lastStep != 83) || res.data.lastStep == 81 || res.data.lastStep == 85) {
                  
                  if (res.data.lastStep == 2 || res.data.lastStep == 3 || res.data.lastStep == 4 || res.data.lastStep == 83) {
                    this.route.navigate(['/sign-up-address-verify'], { queryParams: { url: 'dob' } });
                  } else if (res.data.lastStep == 5) {
                    this.route.navigate(['/sign-up-address-verify'], { queryParams: { url: 'add' } });
                  } else if (res.data.lastStep == 6) {
                    this.route.navigate(['/identity-check-kba'], { queryParams: { url: 'addinfo' } });
                  } else if (res.data.lastStep == 7 || res.data.lastStep == 81 || res.data.lastStep == 85) {
                    this.route.navigate(['/identity-check-kba'], { queryParams: { url: 'kba' } });
                  }
                } else {
                  if (res.data.walkthrough || res.data.email == "customertest2@testdomain.co.uk") {
                    this.route.navigate(['/dashboard'], { queryParams: { tag: "invite" } });
                  }
                  else
                    this.route.navigate(['/dashboard']);
                }
              } else {
                this.pageNavigation = decodeURI(this.returnUrl);
                this._services.setreturnUrl(this.returnUrl);
                

                if (this.pageNavigation.search("energy-switching") != -1) {
                  document.location.href = document.location.origin + "/marketplace/energy-switching/get-quote";
                }
                else {
                  document.location.href = document.location.origin + "/" + this.pageNavigation;
                }
              }
            } else if (res.data.redirectStatus == 1) {
              // New sign up page
              this.route.navigate(['/sign-up-address-verify']);
            } else if (res.data.redirectStatus == 0) {
              this.route.navigate(['/sign-up-personal-details']);
            }
          }
        } else if (res.data.errorCode != 0) {
          this.IsImageLoaded = false;
          this.show_error_message = true;
          this.result = res.data.errorMessage;
          if (res.data.ErrorCode == '7') {
            this.route.navigate(['/access-blocked-signup']);
          }
        }
      } else if (res.statusCode == '1' || res.statusCode == '2') {
        this.IsImageLoaded = false;
        this.show_error_message = true;
        this.result = res.message;
      } else if (res.statusCode == '7') {
        this.route.navigate(['/access-blocked-signup']);
      } else if (res.statusCode == '6') {
        this.route.navigate(['/sign-up-not-verified']);
      } else if (res.statusCode == '9') {
        this.IsImageLoaded = false;
        this.show_error_message = true;
        this.result = 'Your account is temporarily locked, please try signing in again after 24 hours';
      } else if (res.statusCode == '11') {
        this.IsImageLoaded = false;
        this.deactiveUser = true;
      } else {
        this.IsImageLoaded = false;
        this.show_error_message = true;
        this.result = 'Something went wrong';
      }
    },
      (err: HttpErrorResponse) => {
        console.log('Catch exception: ' + err);
        this.IsImageLoaded = false;
        this.show_error_message = true;
        this.result = 'Something went wrong';
        this.isFormDisabled = false;
      }
    );
  }
  keypressMethod() {
    this.show_error_message = false;
  }
  CheckValidation() {
    this.submitted = true;
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.cookieService.set('rememberMeStatus', 'true');
    } else {
      this.cookieService.set('rememberMeStatus', 'false');
    }
  }
  _refreshAccount() {
    this._truelayerService.accountrefresh().subscribe((res: any) => {
      if (res != null) {
        sessionStorage.setItem('action', 'login');
      }
    }, (err: HttpErrorResponse) => {
      console.log('Catch exception: ' + err);
    });
  }

  modelClosed() {
    $('.modal-backdrop').remove();
    $(document.body).removeClass('modal-open');
  }

  LogoutSuccessClose() {
    if (sessionStorage.getItem('sessionLogout') == '1') {
      this.LogoutModal.nativeElement.click();
      sessionStorage.clear();
      localStorage.clear();
      this.startCountdown(this.timerSeconds);
    }
  }

  checkModal() {
    if (sessionStorage.getItem('sessionTimeout') == '1') {
      this.SessionModal.nativeElement.click();
      sessionStorage.clear();
      localStorage.clear();
    } else {      
      $(document.body).removeClass('modal-open');      
    }
  }

  startCountdown(seconds) {
    let counter = seconds;
    const interval = setInterval(() => {
      if (counter >= this.minInSeconds) {
        this.minutes = (counter / this.minInSeconds).toString().split('.');
        this.minutes = +this.minutes[0];
        this.second = counter % this.minInSeconds;
        counter--;

      } else {
        this.minutes = this.noMinutes;
        this.second = counter;
        counter--;
      }

      if (counter < this.noMinutes) {
        if (this.stillHere == this.stillHereCondition) {
          clearInterval(interval);
          this.stillHere = null;
          return;
        } else {
          clearInterval(interval);
          sessionStorage.clear();
          localStorage.clear();
          this.closeBtn.nativeElement.click();
          this.stillHere = null;
        }
      }
    }, this.interval);
  }

  checkValidEmail(e: any) {
    this.show_error_message = false;
    let email = e.target.value;
    if (email == '') {
      this.IsrequiredMsg = true;
      this.IsEmailNotValid = false;
    } else {
      this.IsrequiredMsg = false;
      if (email.search(/^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/) == -1) {
        this.IsEmailNotValid = true;
      } else {
        this.IsEmailNotValid = false;
      }
    }
  }

  checkValidPassword(e: any) {
    this.show_error_message = false;
    let password = e.target.value;
    if (password == '') {
      this.IsPasswordRequiredMsg = true;
      this.IsPasswordLengthValid = false;
    } else {
      this.IsPasswordRequiredMsg = false;
      if (password.length < 8) {
        this.IsPasswordLengthValid = true;
      } else {
        this.IsPasswordLengthValid = false;
      }
    }
  }

  GetArticle() {
    this._CMSservices.GetCategoryItems().subscribe((res: any) => {
      if (res.data != null) {
        sessionStorage.setItem('articles', JSON.stringify(res.data));
      }
    });
  }

  close(){
    this.deactiveUser = false;
  }
}


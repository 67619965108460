import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RemoveAccount, TransactionList, SpendingSummaryList, MobileAppLogs, AddAccount } from '../Models/TrueLayer';
import { environment } from '../../environments/environment';
import { CacheService } from '../Services/cache.service';
import { Subject } from 'rxjs';

@Injectable()
export class TruelayerService {
  constructor(private _http: HttpClient, private cache: CacheService) { }

  // Get globelUrl from app config
  url = environment.configUrl;
  rootUrl = environment.apiUrl;
  baseUrl = this.url['BaseUrl'];

  accountrefresh() {
    return this._http.get<any>(this.rootUrl + '/account/AccountsRefresh');
  }

  AddNewAccounts(addaccountmodel: AddAccount, decToken: any) {
    return this._http.post<any>(this.rootUrl + '/account/AddAccount', addaccountmodel, { headers: new HttpHeaders({ 'authorization': 'Bearer ' + decToken }) });
  }
  
  GetAccountList() {
    return this.GetAllAcountType();    
  }
  IsAccountOverView = new Subject<any>();
  GetAccountListNew(filterList: SpendingSummaryList, removeCache: string = '') {
    const params = new HttpParams()
      .set('AccountID', String(filterList.AccountId))
      .set('startDate', filterList.StartDate)
      .set('endDate', filterList.EndDate).set("id", removeCache);

    return this._http.get<any>(this.rootUrl + '/account/UserAccountsAllType', { params });
  }
  FilterAccountListNew(accountId, startDate, endDate) {
    const params = new HttpParams()
      .set('AccountID', accountId)
      .set('startDate', startDate)
      .set('endDate', endDate);
    return this._http.get<any>(this.rootUrl + '/account/FilterUserAccountsAllType', { params });
  }

  GetTransactionList(transactionlist: TransactionList) {
    const params = new HttpParams()
      .set('AccountID', String(transactionlist.AccountID))
      .set('orderByParameterProc', transactionlist.orderByParameterProc)
      .set('orderByType', transactionlist.orderByType)
      .set('GetClickType', transactionlist.GetClickType)
      .set('startDate', transactionlist.startDate)
      .set('endDate', transactionlist.endDate);
    return this._http.get<any>(this.rootUrl + '/account/TransactionList', { params });
  }

  GetSpendingSummary(spendingSummaryList: SpendingSummaryList) {
    const params = new HttpParams()
      .set('AccountID', String(spendingSummaryList.AccountId))
      .set('startDate', spendingSummaryList.StartDate)
      .set('endDate', spendingSummaryList.EndDate);
    return this._http.get<any>(this.rootUrl + '/account/SpendingSummary', { params });
  }

  GetAllAcountType() {
    return this._http.get<any>(this.rootUrl + '/account/UserAccountsAllType');
  }

  GetAllAccountBalance() {
    return this._http.get<any>(this.rootUrl + '/account/AllAccountBalance');
  }

  RemoveAccount(removeAccount: RemoveAccount) {
    this.cache.deletekey(this.rootUrl + '/account/UserAccountsAllType');
    return this._http.post<any>(this.rootUrl + '/account/RemoveAccount', removeAccount);
  }
  CaptureAddBankMobileLogs(mobileAppLogs: MobileAppLogs) {
    return this._http.post<any>(this.rootUrl + '/account/AddBankRedirectionLogs', mobileAppLogs);
  }
  GetAccountSummary() {
    return this._http.get<any>(this.rootUrl + '/account/AccountsSummary');
  }
  CaptureTransaction(addaccountmodel: AddAccount) {
    return this._http.post<any>(this.rootUrl + '/account/CaptureTransactions', addaccountmodel);
  }

  GetIncreasePercentageInBalance() {
    return this._http.get<any>(this.rootUrl + '/account/GetIncreasePercentageInBalance');
  }

  GetUserAccountBalance(accountIdList: any[]) {
    return this._http.post<any>(this.rootUrl + '/account/GetUserAccountBalance', accountIdList);
  }

  GetRecurringPayments() {
    return this._http.get<any>(this.rootUrl + '/account/RecurringPayments');
  }

}

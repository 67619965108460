import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AddressDetailsUser, ConfirmSwitchDetails, EnergyLinxProviderDetails, GetQuoteDetails } from '../Models/EnergyLinxProviderDetails';


@Injectable()
export class StorageService {
  private _AddressDetailsUser: BehaviorSubject<AddressDetailsUser> = new BehaviorSubject(null);
  private _ProviderDetails: BehaviorSubject<EnergyLinxProviderDetails> = new BehaviorSubject(null);
  private _ConfSwitchDetails: BehaviorSubject<ConfirmSwitchDetails> = new BehaviorSubject(null);
  private _GetQuoteDetail: BehaviorSubject<GetQuoteDetails> = new BehaviorSubject(null);
  public setData(key: any, value: any): void {
    sessionStorage.setItem(key, value);
  }

  public getData(key: any): any {
    return sessionStorage.getItem(key);
  }

  get providerDetails() {
    return this._ProviderDetails;
  }

  EnergyLinxProviderDetail(details: EnergyLinxProviderDetails) {
    this._ProviderDetails.next(details);
  }

  get ConfirmSwitchDetails() {
    return this._ConfSwitchDetails;
  }


  ConfirmSwitchDetail(details: ConfirmSwitchDetails) {
    this._ConfSwitchDetails.next(details);
  }

  get quotedetails() {
    return this._GetQuoteDetail;
  }

  GetQuoteDetail(details: GetQuoteDetails) {
    this._GetQuoteDetail.next(details);
  }


  // for adress
  get addressdetails() {
    return this._AddressDetailsUser;
  }

  AddressDetailsUser(userdetils: AddressDetailsUser) {
    this._AddressDetailsUser.next(userdetils);
  }


}

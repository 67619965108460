import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './Services/auth.service';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  imgSrc = environment.imgEnergyBasURL;
  imgUrl = environment.imgBasURL;
  title = 'sg-design';
  Title = 'Home Component Title';
  analytics_title = 'analytics';

  constructor(router: Router, private authService: AuthService, private bnIdle: BnNgIdleService,
    private meta: Meta, @Inject(DOCUMENT) private dom) {

    let canURL = 'https://assets.ooki.io/assetsImage/ooki.ico';
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  ngOnInit(): void {
    this.meta.addTag({ name: 'twitter', content: this.imgUrl + 'OG_graphic.jpg' });
    this.meta.addTag({ property: 'image', content: this.imgUrl + 'OG_graphic.jpg' });
  }

}

const keyboardUserCssClass = 'keyboardUser';

function setIsKeyboardUser(isKeyboard) {
  const { body } = document;
  if (isKeyboard) {
    body.classList.contains(keyboardUserCssClass) || body.classList.add(keyboardUserCssClass);
  } else {
    body.classList.remove(keyboardUserCssClass);
  }
}

// This is a quick hack to activate focus styles only when the user is
// navigating with TAB key. This is the best compromise we've found to
// keep nice design without sacrifying accessibility.
document.addEventListener('keydown', e => {
  if (e.key === 'Tab') {
    setIsKeyboardUser(true);
  }
});
document.addEventListener('click', e => {
  // Pressing ENTER on buttons triggers a click event with coordinates to 0
  setIsKeyboardUser(!e.screenX && !e.screenY);
});

document.addEventListener('mousedown', () => {
  setIsKeyboardUser(false);
});



    export class CurrentUsageModel {
        constructor(
        public GasUsageType: any,
        public ElectricityUsageType: any,
        public NightPercentageUsage: any,
        public NumberOfBedrooms: any,
        public GasCurrentSpend: any,
        public ElectricityCurrentSpend: any,
        public GasSpendPeriod: any,
          public ElectricitySpendPeriod: any,
          public GasUsageSimpleEstimator: string,
          public ElectricityUsageSimpleEstimator: string
        ) {}
    }

    export class CurrentSupplyModel {
        constructor(
        public IsGasComparison: boolean,
        public IsElectricityComparison: boolean,
        public Economy7: boolean,
        public GasSupplier: any,
        public GasSupplierTariff: any,
        public GasSupplierPaymentMethod: any,
        public ElectricitySupplier: any,
        public ElectricitySupplierTariff: any,
        public ElectricitySupplierPaymentMethod: any
        ) {}
    }
    export class CurrentDetails {
        constructor(
        public currentUsageModel: CurrentUsageModel,
        public currentSupplyModel: CurrentSupplyModel,
        public UserKey: string,
        ) {}
    }
    export class TrackingDetails {
        constructor(
            public Id: number,
            public UserName: string,
            public LookingFor: string,
            public IsSupplierSame: boolean,
            public CurrentGasSupplier: string,
            public CurrentGasTariffPlan: string,
            public CurrentGasPay: string,
            public CurrentElecSupplier: string,
            public CurrentElecTariffPlan: string,
            public CurrentElecPay:string,
            public SelectedPlan:string,
            public EHLReference:string,
            public Consumption:string,
            public CurrentSpend:number,
            public SpendPeriod:string,
            public PropertySize:string,
            public DomainKey:string

        ) {}
    }



    export class Address {
        constructor(
          public PostCode: string
        ) { }
      }
    
      export class AuthToken {
    
        constructor(
            public userName: string,
            public password: string
        ) {}
    
      }
      export class Tariff {
        constructor(
          public UserKey: string
        ) {}
      }
    
      export class FutureTariff {
        constructor(
          public UserKey: string
        ) {}
      }
    
    
      export class SignUpModel {
        constructor(
            public FutureTariffId: string,
            public UserKey: string,
        ) { }
    }
    
    export class SignUpSaveModel {
        constructor(
            public ReqModel: [],
            public UserKey: string,
        ) { }
    }
    
    export class UserAccountModel {
      constructor(
        public SortCode: string,
        public AccountNumber: string,
        public ValidationKey: string
      ) { }
    }
    
    
    
    export class UserAccountDetails {
      AccountHolderName: string = '';
      SortCode: string = '';
      AccountNumber: string = '';
      BankAddress: string = '';
      BankName: string = '';
      constructor() {
        // not initialise
       }
    }
    
    export class UserInfoModel {
      public UserId: string;
      public EmailId: string
      public PostCode: string;
      constructor() {
        // not initialise
      }
    }
    export class EnergyCongratulationModel {
    
        constructor(
            public UserKey: string,
            public DomainKey:string
        ) { }
    }
    
    export class CongratulationModel {
    
        constructor(
            public CustomerData: CongratesObject,
            public ContactTelephone: string,
            public Description: string,
            public Email: string,
            public LogoUri: string
    
        ) {
    
        }
    
    }
    
    export class CongratesObject {
        constructor(public EHLReference: string) {
    
        }
    
    }
    
    <div *ngIf="loader" class="body-bg-loader">
        <div id="m-border-radius_0">
            <div id="loader">
                <div class="text-center">
                    <div class="spinner-border new-vbg" role="status"></div>
                    <div class="please-wait">Please wait..</div>
                    <div class="textloader">{{message}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper" *ngIf="!loader">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 sg-container-shadow">
                <!-- mobile view social media -->
                <div id="logo-wrapper">
                    <img class="img-fluid" src="{{ imgSrc }}logo.png" alt="image not found" />
                </div>
                <div id="title-container">
                    <span>Activate account</span>
                </div>
                <div id="login-form-wrapper">
                    <form [formGroup]="cForm">
                        <div class="form-group">
                            <input type="{{ type }}" placeholder="Password" formControlName="NewPassword"
                                class="form-control" (keyup)="OnPasswordPattern($event.target.value)" [ngClass]="{
                  'is-invalid':
                    forgotPassword.NewPassword.touched &&
                    forgotPassword.NewPassword.errors
                }" />

                            <em  class="eye-icon hover-cursor" (click)="ShowHidePass()"><img alt="image not found"
                                    class="img-fluid" id="open-eye" src="{{ img_src }}" /></em>
                            <div *ngIf="forgotPassword.NewPassword.errors || RequirmentPassword"
                                class="invalid-feedback">
                                <div *ngIf="forgotPassword.NewPassword.errors?.required">
                                    Password is required
                                </div>
                                <div *ngIf="
                    submitted && forgotPassword.NewPassword.errors?.minlength
                  ">
                                    Password must be at least 8 characters
                                </div>
                                <div *ngIf="
                    submitted && forgotPassword.NewPassword.errors?.maxlength
                  ">
                                    Password must not more than 32 characters
                                </div>
                                <div *ngIf="RequirmentPassword" class="invalid-feedback">
                                    Password should satisfy all the requirements
                                </div>
                            </div>
                        </div>

                        <div id="conf-pass" class="form-group">
                            <input type="password" placeholder="Confirm password" formControlName="ConfirmNewPassword"
                                class="form-control" [ngClass]="{
                  'is-invalid':
                    forgotPassword.ConfirmNewPassword.touched &&
                    forgotPassword.ConfirmNewPassword.errors
                }" />
                            <!-- <em  class="eye-icon hover-cursor"><img alt="image not found" class="img-fluid"
                          id="open-eye"
                          src="{{img_src}}"></em> -->
                            <div *ngIf="
                  forgotPassword.ConfirmNewPassword.errors || RequirmentPassword
                " class="invalid-feedback">
                                <div *ngIf="forgotPassword.ConfirmNewPassword.errors.required">
                                    Confirm password is required
                                </div>
                                <div *ngIf="forgotPassword.ConfirmNewPassword.errors.mustMatch">
                                    Both passwords should match
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul class="validation-requirmet">
                                <div id="Requirement-txt">Password Requirements</div>
                                <li [ngClass]="IsUpperLowerCase ? 'green ' : 'cross'">
                                    An uppercase & lowercase character
                                </li>
                                <li [ngClass]="IsNumber ? 'green ' : 'cross'">
                                    Please provide at least one number
                                </li>

                                <li [ngClass]="Islength ? 'green ' : 'cross'">
                                    Password must be between 8-20 characters
                                </li>
                            </ul>
                        </div>

                        <div id="btn-sign-in-wrapper">
                            <button [disabled]="!cForm.valid" class="sg-btn" (click)="onSubmit()">
                                <div class="container" *ngIf="IsImageLoaded">
                                    <div style="margin: 1% auto">
                                        <div class="spinner-border custom-spinner-border" role="status"></div>
                                    </div>
                                </div>

                                <span *ngIf="!IsImageLoaded">Activate</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
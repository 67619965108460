import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

abstract class HttpCache {
 abstract get(req:HttpRequest<any>):HttpResponse<any>|null;
 abstract put(req:HttpRequest<any>,resp:HttpResponse<any>):void;
 abstract clear():void
 abstract deletekey(key:string):void
}

@Injectable({
  providedIn: 'root'
})

export class CacheService implements HttpCache {


  //class variables
  readonly rootUrl = environment.apiUrl;
  private excludedUrls:Array<string> =//[]
  ["/account/AccountsRefresh",

];

  private cache ={};
  private isCacheEnabled:boolean=true;
  //Getting the Cached Value
  UserDetailList =JSON.parse(sessionStorage.getItem('UDList'));
  get(req: HttpRequest<any>): HttpResponse<any> {
    this.UserDetailList=JSON.parse(sessionStorage.getItem('UDList')); //any;
    if (this.UserDetailList != null) {
      if (this.cache[this.UserDetailList.email + req.urlWithParams] != null) {
        let temp: HttpResponse<any>;
        temp = new HttpResponse();
        temp = Object.assign(temp, this.cache[this.UserDetailList.email + req.urlWithParams])
        return temp;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  //Setting the Cached Value
  put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    if(this.isCacheEnabled)
    {
      if(this.excludedUrls.indexOf(req.urlWithParams.replace(this.rootUrl,""))==-1)
      {
        if(req.method == 'GET')
        {
        if (this.UserDetailList != null) {
          this.cache[this.UserDetailList.email + req.urlWithParams] =resp;
          
          }
        }
      }
    }
  }

  //Clearing the Cache
  clear():void{
    this.cache ={};
  }

  //Deleting a keydata
  deletekey(key: string): void {
    if(this.cache[this.UserDetailList.email+key]){
      this.cache[this.UserDetailList.email+key]=null;
    }
  }
}

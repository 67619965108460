import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthToken, Tariff, FutureTariff, Address,CurrentDetails, TrackingDetails, SignUpModel, SignUpSaveModel, 
         EnergyCongratulationModel, UserAccountModel, UserInfoModel } from '../Models/Energy';
import { AppConfigService } from '../Services/AppConfigService.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SavingExpertService {

  constructor(private http: HttpClient, private _globalVariable: AppConfigService) { }


  baseurl = environment.energyApiUrl;
  userName: string = environment.userName;
  password: string = environment.password;
  tokenurl = this._globalVariable.config['Energy']['AuthToken'];
  addressurl = this._globalVariable.config['Energy']['AddressByPostcode'];
  authtoken = new AuthToken('', '');
  tariff = 'Tariff';
  tariffbody = new Tariff('');
  CurrentDetails = this._globalVariable.config['Energy']['CurrentDetails'];
  TrackingUrl = this._globalVariable.config['Energy']['UserTracking'];
  SignUpDetails = this._globalVariable.config['Energy']['GetSignUpDetails'];
  FutureDetails = this._globalVariable.config['Energy']['FutureTariffDetails'];
  TermsAndCondition = this._globalVariable.config['Energy']['TermsAndCondition'];
  futuretariffdetail = new FutureTariff('');
  SaveEnergySignUp = this._globalVariable.config['Energy']['SaveEnergySignUp'];

  EnergyCongratulations = this._globalVariable.config['Energy']['EnergyCongratulations'];

  AccountUrl = this._globalVariable.config['Energy']['ValidateAccountDetails'];
  AddUserInfo = this._globalVariable.config['Energy']['AddUserInfo'];
  VeriryEmailAddress = this._globalVariable.config['Energy']['VeriryEmailAddress'];
  Gettoken() {
    return this.http.post(this.baseurl + this.tokenurl, null);
  }

  GetAddress(address: Address) {
    return this.http.post(this.baseurl + this.addressurl, address, this.httpOptions());
  }

  GetCurrentSupply(address: Address) {
    return this.http.post(this.baseurl + 'GetCurrentSupplyByPostCode', address, this.httpOptions());
  }

  GetTarrif(TariffUserKey: any) {
    this.tariffbody.UserKey = TariffUserKey;
    return this.http.post(this.baseurl + this.tariff, this.tariffbody, this.httpOptions());

  }
  SaveCurrentDetails(currentDetails: CurrentDetails) {
    return this.http.post(this.baseurl + this.CurrentDetails, currentDetails, this.httpOptions());
  }

  GetSignUpDetails(SignUpModel: SignUpModel) {
    return this.http.post(this.baseurl + this.SignUpDetails, SignUpModel, this.httpOptions());
  }

  GetFutureTariffDetails(Uri: any) {
    this.futuretariffdetail.UserKey = Uri;
    return this.http.post(this.baseurl + this.FutureDetails, this.futuretariffdetail, this.httpOptions());
  }
  GetTermsAndCondition(Uri: any) {
    this.futuretariffdetail.UserKey = Uri;
    return this.http.post(this.baseurl + this.TermsAndCondition, this.futuretariffdetail, this.httpOptions());
  }

  SaveSignUpDetails(signupDetails: SignUpSaveModel) {
    return this.http.post(this.baseurl + this.SaveEnergySignUp, signupDetails, this.httpOptions());
  }

  GetSwithedCongratulation(congrates: EnergyCongratulationModel) {
    congrates.DomainKey=environment.configUrl.DomainKey;
    return this.http.post(this.baseurl + this.EnergyCongratulations, congrates, this.httpOptions());
  }
  UserTrackingDetails(trackingDetails: TrackingDetails) {
    trackingDetails.DomainKey= environment.configUrl.DomainKey;
    return this.http.post(this.baseurl + this.TrackingUrl, trackingDetails, this.httpOptions());
  }

  ValidateAccountDetails(model: UserAccountModel) {
    return this.http.post(this.baseurl + this.AccountUrl, model, this.httpOptions());
  }


  httpOptions() {

    let token = sessionStorage.getItem('eToken');
    return {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
    };
  }
  AddUserInfoData(model: UserInfoModel) {
    return this.http.post(this.baseurl + this.AddUserInfo, model)
  }
  VerifyEmail(emailid: string) {
    return this.http.get(this.baseurl + this.VeriryEmailAddress, {
      headers: this.httpOptions().headers,
      params: {
        EmailId: emailid
      }
    });
  }
}


export class Account {
    item_id: string;
    item_text: string;
}
export class AccountIdList {
    constructor(
        public AccountId: string,

    ) { }
}
export class AddAccount {
    constructor(
        public code: string
    ) { }
}
export class TransactionList {
    constructor(
        public AccountID: string[],
        public orderByParameterProc: string,
        public orderByType: string,
        public GetClickType: string,
        public startDate: string,
        public endDate: string
    ) { }
}

export class RemoveAccount {
    constructor(
        public AccountID: string,
    ) { }
}

export class TrueLayerETLRequest {
    constructor(
        public token: string,
        public accountDetails: AccountDetails[],
    ) { }
}
export class AccountDetails {
    constructor(
        public accountId: string,
        public accountType: string

    ) { }
}
export class CategorySummary {
    constructor(public shopping: number,
        public personal: number, public others: number,
        public totalIn: number, public totalOut: number,
        public totalcash: number, public totalDebts: number
    ) { }
}



export class SpendingSummaryList {
    constructor(
        public AccountId: string[],
        public StartDate: string,
        public EndDate: string
    ) { }

}
export class MobileAppLogs {
    constructor(
        public UserId: number,
        public Logs: string,
    ) { }
}

export class AccountSummary {
    accountType: string;
    accountNumber: string;
    logoUrl: string;
    balance: number;
}
export class AccTransactionSummary {
    constructor(public creditTransactions: number,
        public sumOfCredits: number,
        public debitsTransactions: number,
        public sumOfDebits: number,
        public weeklySpends: number) { }
}

export class BalanceIncreaseData
 {
    public userId: number;
    public currentBalance: number;
    public increaseInBalance: number;
    public increaseInPercentage: number
}
export enum ActiveTab {
  AllList="AllList",
  Merchant="Merchant",
  Category="Category"
}
export enum DataAvailability {
  Available = 1,
  NoData = 2,
  Fetching = 3
}

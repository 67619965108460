import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  constructor(private route: Router) {
  }

  FetchSession() {
    let UserDetailList = JSON.parse(sessionStorage.getItem('UDList'));
    if (!UserDetailList) {
      console.error('Invalid Session!');
      this.route.navigate(['/login']);
    } return UserDetailList;
  }
}

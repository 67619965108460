// ____________________________________New Implementation__________________________

export class EngUser {
  constructor(
    public username: string,
    public password: string,

  ) { }
}

export class User {
  constructor(
    public username: string,
    public password: string,

  ) { }
}

export class PostCode {
  constructor(
    public postcode: string
  ) { }
}

export class SignUpRequest {
  constructor(
    public Id: string,
    public token: string,
    public clientId: string,
    public customer: Customer,
    public supplyAddress: SupplyAddress,
    public bankInfo: BankInfo,
    public metaInfo: MetaInfo,

  ) { }
}

export class Customer {
  constructor(
    public title: string,
    public forename: string,
    public surname: string,
    public birthDate: string,
    public email: string,
    public mobile: string

  ) { }
}

export class SupplyAddress {
  constructor(
    public addressID: number,
    public addressDisplay: string,
    public subBuilding: string,
    public buildingName: string,
    public buildingNumber: string,
    public postCode: string,
    public town: string,
    public street: string,

  ) { }
}

export class BankInfo {
  constructor(
    public accountName: string,
    public accountNumber: string,
    public sortCode: string

  ) { }
}

export class MetaInfo {
  constructor(
    public source: string,
    public campaign: string,
    public medium: string,
    public keyword: string,
    public content: string

  ) { }
}

export class TariffRequest {
  constructor(
    public token: string,
    public clientId: string,
    public size: string,
    public postCode: string,
    public buildingNumber: string,
    public gasConsumption: string,
    public electricityConsumption: string,
    public expenditure: string,
    public propertySize: string,
    public interval: string,
    public source: string,
    public campaign: string,
    public medium: string,
    public keyword: string,
    public content: string,
    public a_FullAddress: string,
    public address_line1: string,
    public address_line2: string,
    public town_or_city: string,
    public subBuilding: string,
    public buildingName: string,


  ) { }
}

export class RequestHeader {
  constructor(
    public token: string,
    public clientId: string

  ) { }
}

export class EnergyLinxProviderDetails {
  constructor(
    public supplierName: string,
    public supplierImg: string,
    public SupplierID: string,
    public Features1: string,
    public Features2: string,
    public Features3: string,
    public Features4: string,
    public TraiffName: string,
    public savings: string,
    public tariffName: string,
    public fixedTermEnd: string,
    public monthlySavingNewSwitch: string,
    public expenditure: string

  ) { }
}

export class ConfirmSwitchDetails {
  constructor(
    public ReferenceNumber: string,
    public supplierContact: string,
    public supplierEmail: string,
    public supplierName: string,

  ) { }
}


export class GetQuoteDetails {
  constructor(
    public Expenditure: string,
    public TimeDuration: string,
    public PropertySize: string,
  ) { }
}

export class EmailVerify {
  constructor(
    public Email: string


  ) { }
}

export class EmailVerifyResponse {
  constructor(

    public timed_out: boolean,
    public disposable: boolean,
    public first_name: string,
    public deliverability: string,
    public smtp_score: string,
    public overall_score: number,
    public catch_all: boolean,
    public generic: boolean,
    public common: boolean,
    public dns_valid: boolean,
    public honeypot: boolean,
    public suspect: boolean,
    public recent_abuse: boolean,
    public leaked: boolean,
    public suggested_domain: string,
    public valid: boolean,
    public success: boolean,
    public spam_trap_score: string,
    public request_id: string

  ) { }
}

export class AddressDetailsUser {

  constructor(
    public Firstname: string,
    public Lastname: string,
    public Dob: string,
    public ContactNumber: string,
    public Address: string,
    public Emailid: string,


    public pincode: string,
    public BuildingNumber: string,
    public Posttown: string,
    public subbuilding: string,
    public BuildingName: string,
    public street: string
  ) { }
}

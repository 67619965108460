import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ScoreGeniusService } from '../../Services/score-genius.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import * as Sentry from '@sentry/browser';
import { TooltipDescription } from '../../Models/Tooltip';
import { TransUnionService } from '../../Services/transunion.service';
import * as CryptoJS from 'crypto-js';
import { SessionService } from 'src/app/Services/Session.service';

@Injectable()
export class AuthGuard implements CanActivate {
  
  state: string;
  UserDetailList: any;
  
  logs: string = 'A';
  TooltipData: any;
  idToken: string;
  UserDetailListT: any;
  isCallback: boolean;
  TooltipDescriptionModal = new TooltipDescription('', '', '', '', '', '');
  constructor(
    private _Tservices: TransUnionService,
    private router: Router,
    private _services: ScoreGeniusService,
    private cookieService: CookieService,
    private _avRoute: ActivatedRoute,
    private session: SessionService
  ) {
    this.UserDetailList = JSON.parse(sessionStorage.getItem('UDList'));
    if (!this.UserDetailList && JSON.parse(localStorage.getItem('UDListT'))) {
      this.UserDetailList = JSON.parse(localStorage.getItem('UDListT'));
      sessionStorage.setItem('UDList', JSON.stringify(this.UserDetailList));
    }
    if(this.UserDetailList)
    {
    this.idToken = this.UserDetailList.idToken;
  }
}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (state.url.indexOf('callback?code') > -1) {
      console.log('authguard called: '+state.url);
      this.state = state.root.queryParams['state'];
      let deData = CryptoJS.AES.decrypt(decodeURIComponent(this.state), 'abcd');
      let decryptedInfo = JSON.parse(deData.toString(CryptoJS.enc.Utf8));
      for (let x in decryptedInfo) {
        if (x != 'idToken')
          sessionStorage.setItem(x, decryptedInfo[x]);
      }
      if (!this.idToken) {
        this.AppendLogs('Session could not initialized...');
        if (decryptedInfo && decryptedInfo.idToken!='') {
          this.idToken = decryptedInfo.idToken;
        }
        else { this.AppendLogs('Token could not fetch...'); }
      }

    }

    if (this.idToken != null) {
      this.WriteToSentry();
      return true;
    } else {
      this.AppendLogs('failed,Token not available, redirection did not happen.... ');
      this.WriteToSentry();
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }
      });
      return false;
    }
  }
  AppendLogs(logText: string) {
    this.logs = this.logs.concat('...\n ', logText);
  }
  WriteToSentry() {
    if (this.isCallback) {
      Sentry.withScope((scope) => {
        scope.setExtra('MobileAppLogs', this.logs);
        Sentry.captureMessage('Add Account -Guard Log');
      });
    }
  }
  refreshtoken() {
    this._services.AWSRefreshToken().subscribe(
      (res: any) => {
        if (res != null) {
          console.log('Token refreshed.');
          this.UserDetailList.idToken = res.idToken;
          this.idToken = res.idToken;
        }
      }, (err: HttpErrorResponse) => {
        this.AppendLogs('Error while token refreshed.: -' + err);
      });
  }

  GetTooltip() {
    this._Tservices.GetTooltip().subscribe((res) => {
      if (res.data != null) {
        this.TooltipDescriptionModal.TooltipDerogatory =res.data[0].ttDesccription;
        this.TooltipDescriptionModal.TooltipLoansGuaranteedRate =res.data[1].ttDesccription;
        this.TooltipDescriptionModal.TooltipLoansVariableRate =res.data[2].ttDesccription;
        this.TooltipDescriptionModal.TooltipAccountOverviewDebts =res.data[3].ttDesccription;
        this.TooltipDescriptionModal.TooltipAccountOverviewConnectedAccounts =res.data[4].ttDesccription;
        this.TooltipDescriptionModal.TooltipForPreApproved100 =res.data[5].ttDesccription;
        sessionStorage.setItem('TooltipDescriptionModal', JSON.stringify(this.TooltipDescriptionModal));
        this.cookieService.set('TooltipDescriptionModal',JSON.stringify(this.TooltipDescriptionModal));
      }});
  }
}

<!-- Modal -->
<button #TimerOpen [hidden]="true" data-toggle="modal" (click)="open(adddetailsmodal)" data-target=".bd-example-modal-lg3"></button>
<ng-template #adddetailsmodal let-c="close" let-d="dismiss">
    <div class="modal-content-new">
      <div class="modal-body">        
        <div class="img-icon text-center">          
          <img class="img-fluid" src="{{imgSrc}}iconmodeltimer.svg" alt="image not found">
        </div>
        <div class="info-container">
          <div class="info-txt">You have been inactive for a while and for your security we will log you out automatically in</div>
        </div>
        <div class="timer-container">
          <div class="timer" >
            <ng-container *ngIf="Onedigit; else digits">
              {{minutes}}:0{{second}}
          </ng-container>
          <ng-template #digits>
            {{minutes}}:{{second}}
          </ng-template>
          </div>

        </div>
        <div class="text-center">
          <button type="button" class="btn btn-still" (click)="Refreshtoken();">I’m still here</button>
        </div>
        
      </div>
    </div>
</ng-template>
export class User {
    constructor(
        public username: string,
        public password: string,

    ) { }
}
export class UserAvailability {
    public tid: string;
    public offerid: string;
    public affid: string;
    public sub1: string;
    constructor(
        public email: string
    ) { }
}

export class UserName {
    constructor(
        public username: string

    ) { }
}

export class AWSConfirmPasswordRequest {
    constructor(
        public ConfirmationCode: string,
        public NewPassword: string,
        public UserName: string,
    ) { }
}

export class MarketPlaceCategory {
    constructor(
        public CategoryId: number,
    ) { }
}


export class DeleteBlockedUser {
    constructor(
        public username: string,
        public AccessToken: string
    ) { }
}

export class AWSTransUnionSignUp {
    constructor(
        public userIdentifier: string,
    ) { }
}

export class ProfileData {
    constructor(
        public FirstName: string,
        public LastName: string,
        public MiddleName: string,
        public Email: string,
        public CustomerId: string,
        public DOB: string,
        public Mobile: string,
        public Title: string,
        public Telephone: string,
        public ProfileImage: string,

    ) { }
}
export class UserProfile {
    constructor(
        public SortName: string,
        public FullName: string,
        public Email: string,
        public DOB: string,
        public ProfileImage: string,
        public CustomerId: string,
    ) { }
}
export class UserEmailId {
    constructor(
        public UserEmailId: string

    ) { }
}

export class CheckDeviceRequest {
    constructor(
        public PartyId: number,
        public PayLoad: string,
        public HDMData: string,

    ) { }
}

export class PasswordResetUpdateRequest {
    constructor(
        public TrackingId: string,
        public UserName: string,
    ) { }
}

export interface RegisterInterestModel {
    UserName: string;
    WageRegister: boolean;
}


import { PopupSaveData } from '../Models/PopupCheckEligibilty';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { BehaviorSubject, Observable } from 'rxjs';
import { MarketPlaceAccount,CreditCardCategory } from '../Models/Marketplace';
import { PersonalDetails } from '../Models/PersonalDetails';
import { MarketPlaceCategory } from '../Models/User';
import { Userlogin } from '../Models/Userlogin';
import { ApplyOnePlusOneLoanModel, MarketplaceAdditionalInformation, MarketplaceApplyLog, MarketplaceReferance } from '../Models/EligibilitybasedOffers';
import { CategoryList } from '../Models/Loan';
import { environment } from '../../environments/environment';

@Injectable()
export class MarketPlaceService {
  private orderNumSource = new BehaviorSubject<number>(0);
  currentOrder = this.orderNumSource.asObservable();
  private _newUserSignup: BehaviorSubject<Userlogin> = new BehaviorSubject(null);
  sortOrder: string;
   // Get globelUrl from app config
   url = environment.configUrl;
   readonly rootUrl = environment.apiUrl;
   readonly awsLambdaAPIUrl = this.url['awsLambdaApiURL'];
   readonly HomeOwnershipDropDown = this.url['MPHomeOwnershipDropDown'];
   readonly EmploymentDropDown = this.url['MPEmploymentDropDown'];
   readonly PurposeDropDown = this.url['MPPurposeDropDown'];
   readonly MarketPlaceAPIURL = this.url['MarketPlaceAPIURL'];
   readonly baseUrl = this.url['BaseUrl'];
  private _personalInfo: BehaviorSubject<PersonalDetails> = new BehaviorSubject(null);
  get personalInformation() {
    // do some logic here if needed
    return this._personalInfo;
  }
  personalInfo(details: PersonalDetails) {
    this._personalInfo.next(details);
  }

  // User Signup
  get newUserSignup() {

    // do some logic here if needed
    return this._newUserSignup;
  }
  newUserSignupDetail(UserSignup: Userlogin) {
    this._newUserSignup.next(UserSignup);
  }

  setSortOrder(val: string) {
    this.sortOrder = val;
  }

  getSortOrder() {
    return this.sortOrder;
  }
  constructor(private _http: HttpClient) { }

  MP_CreditCard(CreditCardOffers: MarketplaceAdditionalInformation) {
    return this._http.post(this.rootUrl + '/marketplace/CreditCards', CreditCardOffers);
  }

  GetMarketplaceCreditCardCategory(CreditCard: CreditCardCategory): Observable<any> {
    return this._http.get(this.rootUrl + '/marketplace/Categories/' + CreditCard.SectionID);
  }

  GetMarketPlaceBankAccount(marketplace: MarketPlaceAccount) {
    return this._http.post(this.rootUrl + '/marketplace/BankAccounts', marketplace);
  }

  GetMarketPlaceCategory(marketPlaceCategory: MarketPlaceCategory) {
    return this._http.post<any>(this.rootUrl + '/marketplace/BankAccountCategories', marketPlaceCategory);
  }

  getMpHomeOwnership() {
    return this._http.get(this.rootUrl + '/marketplace/HomeOwnership');
  }

  getMPEmployment() {
    return this._http.get(this.rootUrl + '/marketplace/EmploymentStatus');
  }

  getMPPurpose() {
    return this._http.get(this.rootUrl + '/marketplace/Purpose' );
  }

  // Currently not in use
  // CheckEligibility(User: Checkeligibility) {
  //   const params = new HttpParams()
  //   .set('SectionId', String(User.SectionId))
  //   .set('browserName', User.browserName);
  //   return this._http.get(this.rootUrl + '/marketplace/CheckEligibility');
  // }

  popUpSaveData(popSaveData: PopupSaveData) {
    return this._http.post(this.rootUrl + 'marketplace/SaveEligibility', popSaveData);
  }

  // Loan page
  GetMarketplaceLoanCategory(categoryList: CategoryList): Observable<any> {
    return this._http.get(this.rootUrl + '/marketplace/Categories/' + categoryList.SectionID);
  }
  MP_LoanData(loandata: MarketplaceAdditionalInformation) {
    return this._http.post(this.rootUrl + '/marketplace/EligibilityBasedLoans', loandata);
  }

  ApplyOnePlusOneLoan(OnePlusOneModel: ApplyOnePlusOneLoanModel) {
    return this._http.post(this.rootUrl + '/marketplace/ApplyOnePlusOneLoan', OnePlusOneModel);
    
  }

  ApplyOnePlusReferance(affiliateReference: MarketplaceReferance) {
    return this._http.post<any>(this.rootUrl + '/marketplace/AddaffiliateReference', affiliateReference);
  }

  CaptureApplyLog(applyLogRequest: MarketplaceApplyLog) {
    return this._http.post<any>(this.rootUrl + '/marketplace/ApplyLog', applyLogRequest);
  }

}

export class ApiLogin {
    public username: string;
    public password: string;
    public hdmdata: string;
    public payload: string;
    constructor(
        username: string,
        password: string,
        hdmdata: string,
        payload: string

    ) {
        this.username = username;
        this.password = password;
        this.hdmdata = hdmdata;
        this.payload = payload;
    }
}


export class ApiAuthPassword {
    constructor(
        public username: string,
        public password: string,
    ) { }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessionArticles } from '../Models/Articles';
@Injectable({
  providedIn: 'root'
})
export class CMSIntegrationService {
  timeelapsed: number = 0;
  interval;
  constructor(private _http: HttpClient) { }

  CMSURL = environment.apiUrl;
  grant_type = environment.configUrl['grant_type'];
  clientid = environment.configUrl['client_id'];
  client_Secret = environment.configUrl['client_secret'];
  scope = environment.configUrl['scope'];

  GetAllArticles(){
    return this._http.get(this.CMSURL+'/public-cms/AllArticles');
  }
  GetCategories(){
    return this._http.get(this.CMSURL+'/public-cms/Categories');
  }
  GetCategoryItems() {
    return  this._http.get(this.CMSURL + '/public-cms/Articles');
  }
  GetArticleBySlug(slug:any){
    const params = new HttpParams()
    .set('slug', slug)

    return  this._http.get(this.CMSURL + '/public-cms/GetArticleBySlug',{params});
  }
  GetArticlesByCategories(categories:any[]){
    return this._http.get(this.CMSURL+'/public-cms/GetArticlesByCategory?categories='+categories.join(','))
  }
  CreateNewArticleSession(articles: SessionArticles) {
   return this._http.post(this.CMSURL + '/user/CreateNewArticleSession', articles);
  }
  CloseArticleSession(sessionId:string,duration:number)
  {
    return this._http.post(this.CMSURL + '/user/CloseArticleSession', {"SessionId":sessionId,"DurationInSeconds":duration});
  }
  GetSuggestedArticles(categories:any[]){
    return this._http.get(this.CMSURL+'/public-cms/GetSuggestedArticles?categories='+categories.join(','));
  }
  GetRecommendedArticles(articlesIds:any[]){
    return this._http.get(this.CMSURL+'/public-cms/GetRecommendedArticles?articleIds='+articlesIds.join(','));
  }
  GetFaqBySlug(slug:any) : any{
    const params = new HttpParams()
    .set('slug', slug);
    return  this._http.get(this.CMSURL + '/public-cms/GetFaqBySlug',{params});
  }
  startTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.timeelapsed++;
    }, 1000);
  }
  resetTimer() {
    this.timeelapsed = 0;
    clearInterval(this.interval);
  }
  pauseTimer() {
    clearInterval(this.interval);
  }
  ArticlePreference(article:string) {
    return this._http.post(this.CMSURL + '/user/ArticlePreference', {article});
  }
  isArticlePreference() {
    return this._http.post(this.CMSURL + '/user/IsArticlePreference', {});
  }  
  getUserArticlePreference() {
    return this._http.get<any>(this.CMSURL + '/user/UserArticlePreference');
  }
}

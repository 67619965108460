<div id="page-wrapper" class="container">
  <div id="page-container" class="container-fluid">
  <div class="text-center failed-normal">
     Oops something went wrong
    </div>
    <div id="failed-info-txt" class="text-center failed-weight">
      Unfortunately there appears to have been an error when making this request, 
      Please click here to log back in.
    </div>
      <div class="text-center">
        <button id="btn-try-again" (click)="GoLogin()">Try Again</button>
      </div>
  </div>
</div>


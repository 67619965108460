import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

@Injectable(
  {
    providedIn: 'root'
  }
)
export class GlobalService {
  globalVar:string='Welcome to the Marketplace';
  globalVarUpdate:Observable<string>;
  globalVarObserver:Observer<any>;

  constructor() {
    this.globalVarUpdate = new Observable((observe) => {
      this.globalVarObserver = observe;
    })
  }

  updateGlobalVar(newValue:string) {
    this.globalVar = newValue;
    this.globalVarObserver?.next(this.globalVar);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RaiseDisputePayload } from './../Models/TransUnion';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class TransUnionService {
  constructor(private _http: HttpClient) { }
  //  Get globelUrl from app config
  url = environment.configUrl;
  rootUrl = environment.apiUrl;
  awsAPI = this.url['AwsApi'];
  CreditScoreUrl = this.url['CreditScoreUrl'];
  baseUrl = this.url['rootUrl'];
  MainLoader = new Subject<number>();

  GetPersonalInformation() {
    return this._http.get<any>(this.rootUrl + '/creditscore/CreditReport');
  }
  
  GetTooltip() {
    return this._http.get<any>(this.rootUrl + '/public-auth/GetTooltip');
  }
  GetFinancialAccounts() {
    return this._http.get<any>(this.rootUrl + '/creditscore/OpenedAccountDetails');

  }
  GetClosedAccounts() {
    return this._http.get<any>(this.rootUrl + '/creditscore/ClosedAccountDetails');
  }

  GetRatingScore(cacheKey: number, IsHistory: number = 0) {
    return this._http.get<any>(this.rootUrl + '/creditscore/creditscore?key=' + cacheKey + '&history=' + IsHistory);
  }

  getMyProfileSummary() {
    return this._http.get<any>(this.rootUrl + '/creditscore/UserStatistics');
  }

  Get_Dispute(raiseDisputePayload: RaiseDisputePayload) {
    return this._http.post(this.rootUrl + '/creditscore/RaiseADispute', raiseDisputePayload);
  }

}

import { Injectable } from '@angular/core';
import * as data from '../../assets/app-config.json';

@Injectable()
export class AppConfigService {
    private appConfig;
    configJson: any = (data as any).default;

    constructor() {
        // This is intentional
    }

    loadAppConfig() {
        this.appConfig = this.configJson;
    }

    get config() {
        return this.appConfig;
    }
}

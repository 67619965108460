import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KBAVerificationsRequest, AnswerRequest, ConnectCreditRequest, ExperinId,UserDetails } from '../Models/Experian';
import { environment } from '../../environments/environment';
import { SignUpAddInform } from '../Models/AdditioonalInformation';

@Injectable({
  providedIn: 'root'
})
export class ExperianService {

  constructor(private _http: HttpClient ) {}

   readonly rootUrl = environment.apiUrl;
   RegisterPerson(user: UserDetails): Observable<any> {
    let requestheader = new HttpHeaders({'content-type': 'application/json'});
    return  this._http.post<any>(this.rootUrl + '/public-auth/RegisterPerson', user, {headers: requestheader});
   }
   getKbaQuestionAnswer(verificationsRequest: KBAVerificationsRequest): Observable<any> {
    let requestheader = new HttpHeaders({'content-type': 'application/json'});
    return  this._http.post<any>(this.rootUrl + '/public-auth/verifykba', verificationsRequest, {headers: requestheader});
   }

   getKbaQuestionResponse(Id: ExperinId): Observable<any> {
    let requestheader = new HttpHeaders({'content-type': 'application/json'});
    return  this._http.post<any>(this.rootUrl + '/public-auth/VerifyKBAQuestionsResponse', Id, {headers: requestheader});
   }

   PostKBAAnswer(answerRequest: AnswerRequest): Observable<any> {
    let requestheader = new HttpHeaders({'content-type': 'application/json'});
    return  this._http.post<any>(this.rootUrl + '/public-auth/VerificationsByAnswer', answerRequest, {headers: requestheader});
   }

   ConnectCreditReport(connectCreditRequest: ConnectCreditRequest): Observable<any> {
    let requestheader = new HttpHeaders({'content-type': 'application/json'});
    return  this._http.post<any>(this.rootUrl + '/public-auth/connectcreditreport', connectCreditRequest, {headers: requestheader});
   }
   
   ConnectCreditResponse(Id: ExperinId): Observable<any> {
    return  this._http.get<any>(this.rootUrl + '/public-auth/ConnectCreditResponse/' + encodeURIComponent (Id.Id));
   }

   SaveAdditionalInformation(signUpAddInform: SignUpAddInform) {
    return this._http.post(this.rootUrl + '/user/SaveAdditionalInfo', signUpAddInform);
  }
}
